// eslint-disable
// this is an auto generated file. This will be overwritten

export const getPlarformSchemaChangeId = `query GetPlarformSchema($id: String!) {
  getPlarformSchema(id: $id) {
    id
    Trigger
  }
}
`;

export const getPlarformSchema = `query GetPlarformSchema($id: String!) {
  getPlarformSchema(id: $id) {
    id
    AppConfiguration
    ContentCategories
    Live
    PromotedContent
    PromotedContentnew
    Vod
    VodNew
    Trigger
  }
}
`;
export const listPlarformSchemas = `query ListPlarformSchemas(
  $filter: TablePlarformSchemaFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlarformSchemas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      AppConfiguration
      ContentCategories
      Live
      PromotedContent
      Vod
      VodNew
      Trigger
    }
    nextToken
  }
}
`;
export const getContentandsubscription = `query GetContentandsubscription($id: String!) {
  getContentandsubscription(id: $id) {
    id
    subscriberID
    masterID
    contentDetails
    subscription
    expiry
  }
}
`;
export const listContentandsubscriptions = `query ListContentandsubscriptions(
  $filter: TableContentandsubscriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listContentandsubscriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subscriberID
      masterID
      contentDetails
      subscription
      expiry
    }
    nextToken
  }
}
`;
export const queryContentandsubscriptionsByIdSubscriberIdIndex = `query QueryContentandsubscriptionsByIdSubscriberIdIndex(
  $subscriberID: String!
  $first: Int
  $after: String
) {
  queryContentandsubscriptionsByIdSubscriberIDIndex(
    subscriberID: $subscriberID
    first: $first
    after: $after
  ) {
    items {
      id
      subscriberID
      masterID
      contentDetails
      subscription
      expiry
    }
    nextToken
  }
}
`;
