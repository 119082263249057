import React, { Component } from 'react';
import './App.css';
import back from './images/goback.png';
import select from './images/select.png';
import unselect from './images/unselect.png';
import './controls.css'

class LcevcPlayer extends React.Component {

   constructor(props) {
    super(props);
    var self = this;
	this.state = {
		streamUrl: '',
		showHideResolutionBtn: false,
		showLoader:true,
	 };
	this.onInit();
 	this.changeStreamUrl = this.changeStreamUrl.bind(this);
  }

  	onInit() {

		const bodyTag = document.querySelector("body");
        const shakaScript = document.createElement("script");
		const lcevcScript = document.createElement("script");

		shakaScript.setAttribute(
			"type",
			"text/javascript"
		  );
		  shakaScript.setAttribute(
			"src",
			"../../js/shaka-player.ui.debug.js"
		  );
		  bodyTag.appendChild(shakaScript);

		  lcevcScript.setAttribute(
          "type",
          "text/javascript"
        );        
		lcevcScript.setAttribute(
			"src",
			"../../js/lcevc_dec.min.js"
		  );
		  bodyTag.appendChild(lcevcScript);

		
		setTimeout(() => {

			this.setState({
				showHideResolutionBtn: true,
			});

			this.showResolutionBtn = document.getElementById("showResolution");
			this.resolutionSection = document.getElementById("resolutionContainer");
			this.hideResolutionBtn = document.getElementById("hideResolution");
			this.showResolutionBtn.style.display = "block";
			this.hideResolutionBtn.style.display = "none";
			this.resolutionSection.style.display = "none";

		},500);
	}

  	changeStreamUrl(event){
        this.setState(
            {streamUrl : event.target.value}
        );
    }

  	componentDidMount() {
  		window.scrollTo(0, 0);
		this.connectionInterval = setInterval(() => {
			this.handleConnectionChange();
		}, 1000);
		this.video = document.getElementById('video');
		this.video.addEventListener("playing", this.isPlaying);
		if(this.video) {
			this.video.disablePictureInPicture = true
		}
  	}
  	componentWillUnmount() {
		this.video.removeEventListener("playing", this.isPlaying);
		clearInterval(this.connectionInterval);
		clearInterval(this.seekInterval);
		if(window.player) {
			window.player.unload();
			window.player.destroy();
		}
	    window.$("#playeralertModal").modal('hide');
  	}

	/*handleConnectionChange*/
	handleConnectionChange = () => {
		this.video = document.getElementById('video');
		const condition = navigator.onLine ? 'online' : 'offline';
		if(condition == 'offline') {
			this.video.pause();
			window.$("#playeralertModal").modal('show');
			window.$('#playeralertbody').html('There appears to be a problem with your internet connection. Please check connections and retry.');
		}
	}

	/*IsPlaying function*/
	isPlaying = (event) => {
		this.setState({
		  showLoader: false,
	  	});
  	}
	/*End of IsPlaying function*/

	/*For Video Resolution*/
	recalcRates() {
		this.video = document.getElementById("video");
	  	this.d1 = document.getElementById("totalresolution");
		if (this.video) {

			this.videoStats = window.player.getStats();

			if(window.LCEVCdec) {

				this.d1.innerHTML = window.LCEVCdec.instance ? "Video Resolution : " + window.LCEVCdec.instance.frameWidth + " X " + window.LCEVCdec.instance.frameHeight : 0;

				let fps = window.LCEVCdec.instance ? window.LCEVCdec.instance.frameRate : 0;
				fps = fps ? fps.toFixed(0) : '0';
				if(fps > '0') {
					 document.getElementById('frameRate').innerHTML = "Frame Rate : " + fps + ' fps';
				}
			}

			const tracks = window.player.getVariantTracks();
			for (var iterator = 0; iterator < tracks.length; iterator++) {
				if (tracks[iterator].active) {
					var bitrateVal = (tracks[iterator].bandwidth / 1024).toFixed(0);

					if (bitrateVal > 999) {
						document.getElementById( 'videoBitrate' ).innerHTML = 'Video Bitrate : ' + (bitrateVal / 1000).toFixed(1) + ' Mbps';
					} else {
						document.getElementById( 'videoBitrate' ).innerHTML = 'Video Bitrate : ' + bitrateVal + ' Kbps';
					}
					break;
				}
			}

			if (document.getElementsByTagName('img')[1].hidden) {
				if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('avc1')) {
				var videoCodec = 'H.264';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('av01')) {
				var videoCodec = 'AV1';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('vp09')) {
				var videoCodec = 'VP9';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hvc1')) {
				var videoCodec = 'HEVC';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hev1')) {
					var videoCodec = 'HEVC';
				}
			} else {
				if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('avc1')) {
				var videoCodec = 'LCEVC H.264';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('av01')) {
				var videoCodec = 'LCEVC AV1';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('vp09')) {
				var videoCodec = 'LCEVC VP9';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hvc1')) {
				var videoCodec = 'LCEVC HEVC';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hev1')) {
					var videoCodec = 'LCEVC HEVC';
				}
			}

			if(videoCodec) {
			document.getElementById( 'videoCodec').innerHTML = 'Video Codec : ' + videoCodec;
			}

		}
  	}


 render() {

   	/**
	* Render Functions
	*/

	this.iOSCheck = (/iPhone/i.test(navigator.userAgent));

	this.startPlay = () => {


		if(this.state.streamUrl) {

			const condition = navigator.onLine ? 'online' : 'offline';
			if (condition == 'offline') {
				alert('There appears to be a problem with your internet connection. Please check connections and retry.');
			} else if (this.state.streamUrl.split('.').pop() === 'mp4') {
				window.$("#playeralertModal").modal('show');
				window.$('#playeralertbody').html('This format is not supported. Please specify an HLS or MPEG-DASH stream.');
			} else {

				window.$('.lcevc_player_pplayercontainer').css('visibility','visible');

				const video = document.getElementById('video');
				const ui = video['ui'];
				const controls = ui.getControls();
				const player = controls.getPlayer();

				window.player = player;
				window.ui = ui;

				// Enable the LCEVC enhancement.
				player.configure('lcevc.enabled', true);
				player.configure('lcevc.drawLogo', true);

				// Enable LCEVC decoding on Safari
				player.configure('streaming.useNativeHlsOnSafari', false);
				player.configure('mediaSource.forceTransmux', true);

				// Listen for error events.
				player.addEventListener('error', this.onError);
				controls.addEventListener('error', this.onError);

				// Try to load a manifest.
				// This is an asynchronous process.
				player.load(this.state.streamUrl).then(() => {
					// This runs if the asynchronous load is successful.
					console.info('The video has now been loaded!'); // eslint-disable-line
				}).catch(this.onError); // onError is executed if the asynchronous load fails.
				
				this.seekInterval = setInterval(() => {
					this.recalcRates();
				}, 1000);
			}
			
		} else {
			alert('Enter the manifest URL.');
			window.$("#stream-url").focus();
		}

	}/*end of startPlay function*/

	this.onError = (error) => {
	  	console.error('Error code', error.code, 'object', error);
	    if (error.message.includes('CONTENT_UNSUPPORTED_BY_BROWSER')) {
			window.$("#playeralertModal").modal('show');
	      	window.$('#playeralertbody').html('This video format is not supported by your browser.');
	    } else if(error.code == '4032') {
			window.$("#playeralertModal").modal('show');
	   		window.$('#playeralertbody').html('Stream is not supported by the Shaka player.');
	   	} else {
			window.$("#playeralertModal").modal('show');
	   		window.$('#playeralertbody').html('Something went wrong, Please try again.');
	   	}
	}

	this.hideResolution = () => (event)  => {
		this.showResolutionBtn.style.display = "block";
		this.hideResolutionBtn.style.display = "none";
		this.resolutionSection.style.display = "none";
	}
	
	this.showResolution = () => (event) => {
		this.showResolutionBtn.style.display = "none";
		this.hideResolutionBtn.style.display = "block";
		this.resolutionSection.style.display = "block";
	}


	this.goback = () => (event) => {
		if (document.fullscreenElement) {
	      document.exitFullscreen();
		}
		if(window.player) {
			window.player.unload();
			window.player.destroy();
		}
		window.history.back();
	}

	this.onStreamErr = () => (event) => {
		if (document.fullscreenElement) {
	      document.exitFullscreen();
		}
		const condition = navigator.onLine ? 'online' : 'offline';
		if(condition == 'offline') {
			window.player.unload();
			window.player.destroy();
			window.history.back();
		}
	}

	

   return (

	<div style={{backgroundColor: '#000'}}>

		<div className="lcevc_player_container">
			<span><img id="goback" onClick={this.goback()} className="lcevc_player_goback" src={back} /></span>
			<span>&emsp;</span>
			<input type="text" className="form-control lcevc_player_url_input" id="stream-url" name="text"
				value={this.state.streamUrl} placeholder="Enter the manifest URL" onChange = {this.changeStreamUrl} />
			<span>&emsp;</span>
			<label type="button" className="lcevc_player_url_btn" onClick={this.startPlay}>Load</label>
		</div>

		<div id="lcevc_player_pplayercontainer" className="lcevc_player_pplayercontainer" onMouseMove={this.onMouseEnterHandler}>

			<div data-shaka-player-container id="videoContainer" style={{maxWidth: '100%'}}>
				
				<video data-shaka-player id="video" playsInline autoPlay={!this.iOSCheck} style={{width: '100%', height: 'calc(100vh - 50px)', backgroundColor: '#000000'}}></video>
				
				<div id="resolutionContainer" className="presolutionContainer" style={{left: '0px'}}>
					<div className="presolutioncontent">
							<div id="totalresolution">Video Resolution : </div>
							<div id="videoBitrate"> Video Bitrate : </div>
							<div id="videoCodec"> Video Codec : </div>
							<div id="frameRate"> Frame Rate : </div>
					</div>
				</div>

				<div> 
					{ 
						(this.state.showLoader)?( 
							<div id="launch_player_loading"></div>
						) : ( <span></span> ) 
					}
				</div>

				<div id="top_label" className="ptoplabel">
					<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
					<div className="ptoplabelcontainer">
						{ 
						(this.state.showHideResolutionBtn)?( 
							<img id="hideResolution" onClick={this.hideResolution()} className="presoultionbtn" src={select} />
						) : ( <span></span> ) 
						}
						
						<img id="showResolution" onClick={this.showResolution()} className="presoultionbtn" src={unselect} />
					</div>
				</div>


				{/*Modal Window*/}
				<div className="modal fade" id="playeralertModal" role="dialog">
					<div className="modal-dialog" >
						<div className="modal-content">
							<div className="modal-body" id="playeralertbody">
							</div>
							<div className="playeralert_modal_btn_container">
								<span className="cursor_pointer playeralert_modal_btn" data-dismiss="modal" onClick={this.onStreamErr()}>Ok</span>
							</div>
						</div>
					</div>
				</div>
				{/*End of Modal Window*/}

			</div>
			
	    </div>
	
	</div>

    );

   }
}

export default LcevcPlayer; 