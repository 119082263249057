// eslint-disable
// this is an auto generated file. This will be overwritten

export const onCreatePlarformSchema = `subscription OnCreatePlarformSchema(
  $id: String
  $AppConfiguration: AWSJSON
  $ContentCategories: AWSJSON
  $Live: AWSJSON
  $PromotedContent: AWSJSON
) {
  onCreatePlarformSchema(
    id: $id
    AppConfiguration: $AppConfiguration
    ContentCategories: $ContentCategories
    Live: $Live
    PromotedContent: $PromotedContent
  ) {
    id
    AppConfiguration
    ContentCategories
    Live
    PromotedContent
    Vod
    Trigger
  }
}
`;
export const onUpdatePlarformSchema = `subscription OnUpdatePlarformSchema($id: String) {
  onUpdatePlarformSchema(id: $id) {
    id
    AppConfiguration
    ContentCategories
    Live
    PromotedContent
    PromotedContentnew
    Vod
    VodNew
    Trigger
  }
}
`;
export const onDeletePlarformSchema = `subscription OnDeletePlarformSchema(
  $id: String
  $AppConfiguration: AWSJSON
  $ContentCategories: AWSJSON
  $Live: AWSJSON
  $PromotedContent: AWSJSON
) {
  onDeletePlarformSchema(
    id: $id
    AppConfiguration: $AppConfiguration
    ContentCategories: $ContentCategories
    Live: $Live
    PromotedContent: $PromotedContent
  ) {
    id
    AppConfiguration
    ContentCategories
    Live
    PromotedContent
    Vod
    Trigger
  }
}
`;
export const onCreateContentandsubscription = `subscription OnCreateContentandsubscription(
  $id: String
  $subscriberID: String
  $masterID: String
  $contentDetails: AWSJSON
  $subscription: String
) {
  onCreateContentandsubscription(
    id: $id
    subscriberID: $subscriberID
    masterID: $masterID
    contentDetails: $contentDetails
    subscription: $subscription
  ) {
    id
    subscriberID
    masterID
    contentDetails
    subscription
    expiry
  }
}
`;
export const onUpdateContentandsubscription = `subscription OnUpdateContentandsubscription(
  $id: String
  $subscriberID: String
  $masterID: String
  $contentDetails: AWSJSON
  $subscription: String
) {
  onUpdateContentandsubscription(
    id: $id
    subscriberID: $subscriberID
    masterID: $masterID
    contentDetails: $contentDetails
    subscription: $subscription
  ) {
    id
    subscriberID
    masterID
    contentDetails
    subscription
    expiry
  }
}
`;
export const onDeleteContentandsubscription = `subscription OnDeleteContentandsubscription(
  $id: String
  $subscriberID: String
  $masterID: String
  $contentDetails: AWSJSON
  $subscription: String
) {
  onDeleteContentandsubscription(
    id: $id
    subscriberID: $subscriberID
    masterID: $masterID
    contentDetails: $contentDetails
    subscription: $subscription
  ) {
    id
    subscriberID
    masterID
    contentDetails
    subscription
    expiry
  }
}
`;
