import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import logo from './images/logo.png';
import MpegLcevcLogo from './images/mpeg5_lcevc_logo.png';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

class ForgotPassword extends React.Component {
 

   constructor(props) {
    super(props);
    this.state = {
      fuser: '',
      showLoginBtn: true,
      isStaging: false,
 };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

   

  handleSubmit(event) {
    event.preventDefault();
     if(this.state.fuser.match(/\s/g)) {
      alert('Spaces are not allowed in the username field');
    } else {
      this.setState({
        showLoginBtn : false,
      });
      Auth.forgotPassword(this.state.fuser)
      .then(user => this.displaydata(user))
      .catch(err => this.displayerror(err)); 
    }   
  }

  displaydata(user) {
    //console.log(user);
    if(user) {
      localStorage.setItem('userReuestChangePwd',this.state.fuser);
      alert("Verification code has been sent to the registered email "+user.CodeDeliveryDetails.Destination);
      this.props.history.push('/changePassword')
    } else {
      this.setState({
          showLoginBtn : true,
        });
        alert("Invalid Credentials. Try again.")
    }
    
  }

  displayerror(err) {
    //console.log(err);
    this.setState({
          showLoginBtn : true,
        });
    if(err.code == "NotAuthorizedException") {
      alert('Cannot reset password for the user, as there is no register/verified email or phone number.')
    } else if  (err.code == "UserNotFoundException") {
      alert('User name not found.')
    } else {
      alert(err.message)
    }
  }


 render() {
   return (
      <div className="container-fluid prelogin_bg">
      <div className="row">

      <div className="col-md-offset-3 col-md-8 col-xs-12 prelogin_container">
      <div className="col-md-2 col-sm-3 col-xs-1"></div>
      <div className="col-md-5 col-sm-6 col-xs-10 login_container">
      <form id="forgotpwd-form" name="form"  onSubmit={this.handleSubmit}  role="form">
      
      <div className="logo_container">
        {
          (this.state.isStaging) ? (
            <span>
              <img src={logo} alt="LOGO"/>
              <div><img src={MpegLcevcLogo} alt="MPEG-5 LCEVC"/></div>
            </span>
          ) : (
            <span>
              <img src={logo} alt="LOGO"/>
              <div><img src={MpegLcevcLogo} alt="MPEG-5 LCEVC"/></div>
            </span>
          )
        }
      </div>
      
      <div className="col-md-12">
      <div className="col-md-2"></div>
      <div className="form-group col-md-8 login_form_group">                
      <div className="login__row display_flex">
      <input className="login__input" type="text" id="fuser" value={this.state.fuser} onChange={this.handleChange} autoFocus minLength="1" required/>
      <span className="floating-label">Username</span>
      </div>
      </div>
      <div className="col-md-2"></div>
      </div>

     

      {
        (this.state.showLoginBtn) ? (
          <div className="col-md-12 login_btn_container padding_top_20">
             <button className="btn login_btn login_btn_pwd" type="submit" value="Submit"><span>Forgot Password</span></button>  
          </div>
        ) : (
          <div id="loader" className="col-md-12 login_btn_container loginLoaderContainer">
            <div className="loader loginLoader"></div>
          </div>
        )
      }

      
      </form>

      <div className="col-md-12 padding_top_20">
        <Link className="link_text prelogin_anchor" to="/">Sign In</Link>
      </div>
     
      </div>
      <div className="col-md-5 col-sm-3 col-xs-1"></div>
      </div>


      </div>
      </div>   
      );
   }

}

export default ForgotPassword;