import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import logo from './images/logo.png';
import MpegLcevcLogo from './images/mpeg5_lcevc_logo.png';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator, SignUp } from 'aws-amplify-react';
import HubspotForm from "react-hubspot-form";

Amplify.configure(awsconfig);

class iOSSignUp extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
      ruser: '',
      firstname: '',
      lastname: '',
      companyname: '',
      email: '',
      rpass: '',
      UserId: '',
      confirmpassword: '',
      showLoginBtn: true,
      showPassword: false,
      showConfirmPassword: false,
      isStaging: false,
      setPassword: false,
      hubspotFirstName: '',
      hubspotLastName: '',
      hubspotCompanyName: '',
      hubspotEmail: '',
 };
 	this.onInit();
    this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  onInit() {
  	
    function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
    var userIdFSet = randomString(9, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    var userIdSSet = randomString(5, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    var userIdTSet = randomString(5, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    var userIdFoSet = randomString(9, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

	this.setState({
		UserId : userIdFSet+'-'+userIdSSet+'-'+userIdTSet+'-'+userIdFoSet,
	});
    //console.log(this.state.UserId);
    
  }

  componentDidMount(){

	const script = document.createElement("script");

    script.src = "https://js.hsforms.net/forms/v2.js";
    script.async = true;

	document.body.appendChild(script);
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }


  handelNativeForm(form) {
  	//console.log(form);

  	for (var i=0;i<form.length;i++) {

	    if(form[i].name === 'firstname') {
	    	var firstName= form[i].value;
	    	this.setState({
		      hubspotFirstName : firstName,
		    });
	    }

	    if(form[i].name === 'lastname') {
	    	var lastname= form[i].value;
	    	this.setState({
		      hubspotLastName : lastname,
		    });
	    }

	    if(form[i].name === 'company') {
	    	var company= form[i].value;
	    	this.setState({
		      hubspotCompanyName : company,
		    });
	    }

	    if(form[i].name === 'email') {
	    	var email= form[i].value;
	    	this.setState({
		      hubspotEmail : email,
		    });
	    }
	}

	this.setState({
      setPassword : true,
    });
	window.$('#showNativeForm').hide();
  }

  


	render() {

	this.submitFullForm = () => (event) => {
		//event.preventDefault();
		
		if(this.state.ruser.match(/\s/g)) {
			var modyContent = "<p>Spaces are not allowed in the username field</p>";
			window.$(".hubspot_modal_body").html(modyContent);
			window.$('#hubspotModal').modal('show');
			window.$("#ruser").focus();
		} else if(/[^A-Za-z\d]/.test(this.state.ruser)) {
			var modyContent = "<p>Special characters are not allowed in the username field</p>";
			window.$(".hubspot_modal_body").html(modyContent);
			window.$('#hubspotModal').modal('show');
			window.$("#ruser").focus();
		} else if(this.state.ruser.length < 5) {
			var modyContent = "<p>Username should be minimum 5 characters</p>";
			window.$(".hubspot_modal_body").html(modyContent);
			window.$('#hubspotModal').modal('show');
			window.$("#ruser").focus();
		}

		else if(this.state.rpass.match(/\s/g)) {
			var modyContent = "<p>Spaces are not allowed in the password field</p>";
			window.$(".hubspot_modal_body").html(modyContent);
			window.$('#hubspotModal').modal('show');
			window.$("#rpass").focus();
		} else if(/[^A-Za-z\d]/.test(this.state.rpass)) {
			var modyContent = "<p>Special characters are not allowed in the password field</p>";
			window.$(".hubspot_modal_body").html(modyContent);
			window.$('#hubspotModal').modal('show');
			window.$("#rpass").focus();
		} 

		else if(this.state.confirmpassword.match(/\s/g)) {
			var modyContent = "<p>Spaces are not allowed in the confirm password field</p>";
			window.$(".hubspot_modal_body").html(modyContent);
			window.$('#hubspotModal').modal('show');
			window.$("#confirmpassword").focus();
		} else if(/[^A-Za-z\d]/.test(this.state.confirmpassword)) {
			var modyContent = "<p>Special characters are not allowed in the confirm password field</p>";
			window.$(".hubspot_modal_body").html(modyContent);
			window.$('#hubspotModal').modal('show');
			window.$("#confirmpassword").focus();
		} 

		else if (this.state.rpass != this.state.confirmpassword) {
			var modyContent = "<p>Password didn't match</p>";
			window.$(".hubspot_modal_body").html(modyContent);
			window.$('#hubspotModal').modal('show');
		} else {
			this.setState({
	      showLoginBtn : false,
	    });
	    Auth.signUp({
		  username: this.state.ruser, 
		  password: this.state.rpass, 
		  attributes: { 
		  	email: this.state.hubspotEmail,
		  	picture: "image",
		  	locale: "expiry",
	      	profile: "19",
	      	name: "O1A74iJKw-NimBe-2kp3P-m4ZzHdWKr",
	      	nickname: this.state.UserId,
	      	given_name: this.state.hubspotFirstName, 
		  	family_name: this.state.hubspotLastName, 
		  	middle_name: this.state.hubspotCompanyName, 
		   }
		}).then(user => this.displaydata(user))
	    .catch(err => this.displayerror(err)); 
		}
		
	}

	this.displaydata = (user) => {
  	 //console.log(user);
	    if(user) {
	    	var newLine = "\r\n"
		    var msg = 'Registration successful.'
		    msg += newLine;
		    msg += "A verification email has been sent to the address provided. Please check your inbox (and junk folder) to verify and login.";
			window.$(".hubspot_modal_body").html(msg);
			window.$('#hubspotModal').modal('show');
			this.setState({
		      setPassword : false,
		    });
			window.$('#showNativeForm').show();
	      	//window.location.reload();
	    } else {
	    	this.setState({
	          showLoginBtn : true,
	        });
	        window.alert("Invalid Credentials. Try again.")
	    }
	    
	}

  	this.displayerror = (err) => {
	    //console.log(err);
	    this.setState({
	      showLoginBtn : true,
	    });
	    window.$(".hubspot_modal_body").html(err.message);
		window.$('#hubspotModal').modal('show');
	}

	this.toggleShowPassword = () => (event) => {
	    this.setState({ showPassword: !this.state.showPassword });
	}

	this.toggleConfirmShowPassword = () => (event) => {
	    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
	}

		return (
			<div className="container-fluid prelogin_access_bg">
			<div className="row">

			<div className="col-md-12 col-xs-12 prelogin_access_container">
			<div className="col-md-4 col-sm-2 col-xs-0"></div>
			<div className="col-md-4 col-sm-8 col-xs-12 login_container prelogin_access_bottom_space">
            <form id="register-form" name="form" role="form">
			
			<div className="logo_container">
				{
		          (this.state.isStaging) ? (
		            <span>
		              <img src={logo} alt="LOGO"/>
		              <div><img src={MpegLcevcLogo} alt="MPEG-5 LCEVC"/></div>
		            </span>
		          ) : (
		            <span>
		              <img src={logo} alt="LOGO"/>
		              <div><img src={MpegLcevcLogo} alt="MPEG-5 LCEVC"/></div>
		            </span>
		          )
		        }
			</div>

			{/*NATIVE HUBSPOT FORM*/}

			<div className="col-md-12 zero_padding" id="showNativeForm">
			
				<div className="form-group col-md-12 access_login_form_group">
			    	<HubspotForm
				        region= "na1"
						portalId= "19969824"
						formId= "9dd1e5b6-f61d-4cce-8717-2daca0ed4d92"
				        onSubmit={(form) => this.handelNativeForm(form)}
				        loading={<div></div>}
				    />
				</div>
					
			</div>

			{
		    	(this.state.setPassword) ? ( 

		    	<div className="col-md-12 zero_padding" id="showCognitoFields">
		    		<div className="col-md-12 hubspot_set_credential_txt">Set user credentials:</div>
					{/*Text area to enter Username*/}
		            <div className="form-group col-md-12 access_login_form_group">                
		            <div >
		            <label className="hubspot_label">User name</label>
		            <input className="hubspot_login_input" type="user" id="ruser" value={this.state.ruser} onChange={this.handleChange} autoFocus minLength="5" required/>
		            </div>
		            </div>

					<div className="form-group col-md-12 access_login_form_group">
					    <div>
					    {
			                (this.state.showPassword) ? (
			                	<span><label className="hubspot_label">Password</label>
			                  	<input className="hubspot_login_input" type="text" autoComplete="new-password" id="rpass" value={this.state.rpass} onChange={this.handleChange} minLength="8" required />
			                    </span>
			                ) : (
			                <span><label className="hubspot_label">Password</label>
			                 <input className="hubspot_login_input" type="password" autoComplete="new-password" id="rpass" value={this.state.rpass} onChange={this.handleChange} minLength="8" required />
			                  </span>
			                )
			            }
			            {
			                (this.state.showPassword) ? (
			                  <span className="glyphicon glyphicon-eye-open form-control-feedback toggle_password top_27 hubspot_glyphicon" onClick={this.toggleShowPassword()}></span>
			                ) : (
			                  <span className="glyphicon glyphicon-eye-close form-control-feedback toggle_password top_27 hubspot_glyphicon" onClick={this.toggleShowPassword()}></span>
			                )
			            }
			            </div>
					</div>

					<div className="form-group col-md-12 access_login_form_group">
					    <div>
					    {
			                (this.state.showConfirmPassword) ? (
			                  <span><label className="hubspot_label">Confirm password</label>
			                  <input className="hubspot_login_input" type="text" id="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} minLength="8" required />
			                    </span>
			                ) : (
			                  <span><label className="hubspot_label">Confirm password</label>
			                  	<input className="hubspot_login_input" type="password" id="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} minLength="8" required />
			                    </span>
			                )
			            }
			            {
			                (this.state.showConfirmPassword) ? (
			                  <span className="glyphicon glyphicon-eye-open form-control-feedback toggle_password top_27 hubspot_glyphicon" onClick={this.toggleConfirmShowPassword()}></span>
			                ) : (
			                  <span className="glyphicon glyphicon-eye-close form-control-feedback toggle_password top_27 hubspot_glyphicon" onClick={this.toggleConfirmShowPassword()}></span>
			                )
			            }
			            </div>
					</div>

					{
			            (this.state.showLoginBtn) ? (
			              <div className="col-md-12 login_btn_container padding_top_20">
			                <div className="login_btn signup_btn" onClick={this.submitFullForm()}><span>Sign Up</span></div>  
			              </div>
			            ) : (
			              <div id="loader" className="col-md-12 login_btn_container loginLoaderContainer">
			                <div className="loader loginLoader"></div>
			              </div>
			            )
			        }

				</div>

		    	) : (<span></span>) }

			

			
			</form>

			</div>
			<div className="col-md-4 col-sm-2 col-xs-0"></div>
			</div>


			{/*hubspot Popup Modal Window*/}
		        <div className="modal fade" id="hubspotModal" role="dialog">
		          <div className="modal-dialog hubspot_modal_dialog">
		            <div className="modal-content hubspot_modal_content">
		              
		            	 <div className="modal-header hubspot_modal_header">
					        <button type="button" className="close" data-dismiss="modal">&times;</button>
					      </div>

		              <div className="modal-body hubspot_modal_body">
		                
		              </div>
		             
		            </div>
		            
		          </div>
		        </div>
		      {/*End of hubspot Popup Modal Window*/}


			</div>
			</div>


			);
	}
}

export default iOSSignUp;