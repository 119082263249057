import React from 'react';
import './App.css';
import back from './images/goback.png';
import play from './images/1.png';
import pause from './images/2.png';
import rewind from './images/3.png';
import forward from './images/4.png';
import select from './images/select.png';
import unselect from './images/unselect.png';
import expand from './images/expand.png';
import unMute from './images/volume_full.png';
import mute from './images/volume_off.png';
import lcevc from './images/lcevc.png';

class HLSPlayer extends React.Component {

   constructor(props) {
    super(props);
    var self = this;
    this.state = {
      	video: '',
      	canvas: '',
      	hlsPlayer: '',
      	percentage:'',
      	trackList: [],
      	trackListLength : false,
      	showLoader:true,
      	showSeekLoader:false,
      	showLiveBnt:false,
      	showHideResolutionBtn: false,
      	showPlayButton: false,
      	playingVideoPaused: false,
      	duration: '',
      	timeout: '',
      	seekplay: false,
 	  };
 	this.webgl_support();
    //this.onInit();
  }

	webgl_support () { 
			
			var nVer = navigator.appVersion;
			var nAgt = navigator.userAgent;
			var browserName  = navigator.appName;
			var fullVersion  = ''+parseFloat(navigator.appVersion); 
			var majorVersion = parseInt(navigator.appVersion,10);
			var nameOffset,verOffset,ix;

			var OSName="Unknown OS";
			if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows";
			if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS";
			if (navigator.appVersion.indexOf("X11")!=-1) OSName="UNIX";
			if (navigator.appVersion.indexOf("Linux")!=-1) OSName="Linux";

			/*To find WEBGL is available and Version*/
			// Create canvas element. The canvas is not added to the
		    // document itself, so it is never displayed in the
		    // browser window.
			var canvas = document.createElement("canvas");
		    // Get WebGLRenderingContext from canvas element.
		    var gl = canvas.getContext("webgl")
		      || canvas.getContext("experimental-webgl");

			if ((verOffset=nAgt.indexOf("Trident"))!=-1) {
			 browserName = "Internet Explorer";
			 var IEVersion = verOffset=nAgt.indexOf("rv")
			 fullVersion = nAgt.substring(IEVersion+3);
			 	window.history.back();
			 	if(OSName == 'MacOS') {
			 		alert(browserName +' is not supported. Please use Chrome, Firefox or Safari.');
			 	} else if(OSName == 'Windows') {
			 		alert(browserName +' is not supported. Please use Chrome, Firefox or Edge.');
			 	} else {
			 		alert(browserName +' is not supported. Please use Chrome, Firefox');
			 	}
			}
			
			else if ((verOffset=nAgt.indexOf("OPR"))!=-1) {
				browserName = "Opera";
				fullVersion = nAgt.substring(verOffset+4);
			 	if( gl && gl instanceof WebGLRenderingContext && typeof(Worker) !== "undefined" ){
				 	/*Proceed to play the content*/
				 	this.onInit();
				} else {
				 	window.history.back();
				 	alert(browserName +' is not supported for playback of this stream, please update to the latest version.');
				}
			}

			else if ((verOffset=nAgt.indexOf("Edge"))!=-1) {
			 browserName = "Edge";
			 fullVersion = nAgt.substring(verOffset+5);
			 majorVersion = parseInt(''+fullVersion,10);
			 	if( majorVersion >= '16' && gl && gl instanceof WebGLRenderingContext && typeof(Worker) !== "undefined" ){
				 	/*Proceed to play the content*/
				 	this.onInit();
				} else {
				 	window.history.back();
				 	alert(browserName +' version '+ majorVersion+' is not supported for playback of this stream, please update to the latest version.');
				}
			}
			
			else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
			 browserName = "Microsoft Internet Explorer";
			 fullVersion = nAgt.substring(verOffset+5);
			 	window.history.back();
				if(OSName == 'MacOS') {
			 		alert(browserName +' is not supported. Please use Chrome, Firefox or Safari.');
			 	} else if(OSName == 'Windows') {
			 		alert(browserName +' is not supported. Please use Chrome, Firefox or Edge.');
			 	} else {
			 		alert(browserName +' is not supported. Please use Chrome, Firefox');
			 	}
			}
			
			else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
			 browserName = "Chrome";
			 fullVersion = nAgt.substring(verOffset+7);
			 majorVersion = parseInt(''+fullVersion,10);
				if( majorVersion >= '57' && gl && gl instanceof WebGLRenderingContext && typeof(Worker) !== "undefined" ){
				 	/*Proceed to play the content*/
				 	this.onInit();
				} else {
					window.history.back();
				 	alert(browserName +' version '+ majorVersion+' is not supported for playback of this stream, please update to the latest version.');
				}
			}
			
			else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
			 browserName = "Safari";
			 fullVersion = nAgt.substring(verOffset+7);
			 if ((verOffset=nAgt.indexOf("Version"))!=-1) 
			   fullVersion = nAgt.substring(verOffset+8);
				majorVersion = parseInt(''+fullVersion,10);
				if( majorVersion >= '11' && gl && gl instanceof WebGLRenderingContext && typeof(Worker) !== "undefined" ){
				 	/*Proceed to play the content*/
				 	this.onInit();
				} else {
				 	window.history.back();
				 	alert(browserName +' version '+ majorVersion+' is not supported for playback of this stream, please update to the latest version.');
				}
			}
			// In Firefox, the true version is after "Firefox" 
			else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
			 browserName = "Firefox";
			 fullVersion = nAgt.substring(verOffset+8);
			 majorVersion = parseInt(''+fullVersion,10);
				if( majorVersion >= '53' && gl && gl instanceof WebGLRenderingContext && typeof(Worker) !== "undefined" ){
				 	/*Proceed to play the content*/
				 	this.onInit();
				} else {
				 	window.history.back();
				 	alert(browserName +' version '+ majorVersion+' is not supported for playback of this stream, please update to the latest version.');
				}
			} 

			else {
				if(OSName == 'MacOS') {
			 		alert('Browser is not supported. Please use Chrome, Firefox or Safari.');
			 	} else if(OSName == 'Windows') {
			 		alert('Browser is not supported. Please use Chrome, Firefox or Edge.');
			 	} else {
			 		alert('Browser is not supported. Please use Chrome, Firefox.');
			 	}
			}
	
	}


  	onInit() {
	   
  		localStorage.setItem('routefromplayer', 'fromPlayer');
  		var userName = localStorage.getItem('userName');
    	var manifestURL = localStorage.getItem('manifestURL');
    	var videoBaseURI = localStorage.getItem('videoBaseURI');
    	var isExternal = localStorage.getItem('external');
		var actualUrl = videoBaseURI + manifestURL;
		var category = localStorage.getItem('category');
		var codec = localStorage.getItem('codec');

		var fromContent = localStorage.getItem('fromContent');
		
		
		if(userName) {
			if((manifestURL != null && manifestURL != undefined)) {

		var interval = setTimeout(() => {
			
			
      		if( this.hlsPlayer ){
				this.hlsPlayer.destroy();
				if (this.hlsPlayer.bufferTimer) {
		          clearInterval(this.hlsPlayer.bufferTimer);
		          this.hlsPlayer.bufferTimer = null;
		        }
				this.hlsPlayer = null;
			}
			this.hlsPlayer = new window.Hls({
				debug: false,
        		enableWorker: true,
        		defaultAudioCodec: null
			});

			this.video = document.getElementById('video');
      		this.canvas = document.getElementById('canvas');

      		this.hlsPlayer.attachLcevc(this.canvas, {});
			
			if(category == 'live' && isExternal == '1') {
				this.hlsPlayer.loadSource(manifestURL);
				this.hlsPlayer.autoLevelCapping = -1;
			} else {
				this.hlsPlayer.loadSource(actualUrl);
				this.hlsPlayer.autoLevelCapping = -1;
			}

		    
		    this.hlsPlayer.attachMedia(this.video);

		    this.hlsPlayer.on(window.Hls.Events.BUFFER_CREATED,function bufferCreated() {	
  				// this.hlsPlayer.currentLevel = '0';
  				if(fromContent == '1') {
  					window.setTimeout( function videoPlay(){
						this.video.play();
					}, 500 );
  					localStorage.setItem('fromContent', '0');
  				} else {
					this.video.pause();
  				}
	          	
	      	});
	      	// extend hls to provide audio bitrate
			var audioKBitsPerSecond = 0;
		    var audioFragCount = 0;
		    this.hlsPlayer.on( window.Hls.Events.FRAG_PARSED, function( event, data ){
		     	var frag = data.frag;
		      	if( frag ){
			        if(frag.type === 'audio' && frag._byteRange && frag._byteRange.length === 2) {
			          var bytes = frag._byteRange[1] - frag._byteRange[0];
			          var bytesPerSecond = bytes / frag.duration;
			          var kBitsPerSecond = bytesPerSecond / 1000 * 8;
			          //console.log('audioBitrate')
			          // average
			          audioKBitsPerSecond = (audioKBitsPerSecond * audioFragCount++ + kBitsPerSecond) / audioFragCount;
			          	if(document.getElementById('audioBitrate') === null || document.getElementById('audioBitrate') === undefined) {
						    //logic
						} else {
							document.getElementById('audioBitrate').innerHTML = "Audio bitrate : " + audioKBitsPerSecond.toFixed(0) + ' kbps';
						}
			          //console.log(audioKBitsPerSecond);
			        }
		    	}
			});
			

			//h265 error case
			this.hlsPlayer.on(window.Hls.Events.ERROR,function(event, data) {
	      		if(data.details == 'manifestIncompatibleCodecsError') {
		      		setTimeout(() => {
				  		window.history.back();
				    }, 1000);
				    alert('This base video codec is not supported by your browser.');
	      		}
	      		if(data.details == 'manifestParsingError' && data.reason == 'no EXTM3U delimiter') {
		      		setTimeout(() => {
				  		window.history.back();
				    }, 1000);
				    if( (codec == '5' || codec == '6') ) {
				    	alert('Your player supports LCEVC but unfortunately does not support the AV1 base codec. Please try on another player.');
				    } else if( (codec == '7' || codec == '8') ) {
				    	alert('Your player supports LCEVC but unfortunately does not support the VP9 base codec. Please try on another player.');
				    }
	      		}
	      	});

	      	this.setState({
	          showHideResolutionBtn: true,
	          showPlayButton: true,
	        });

		    this.showResolutionBtn = document.getElementById("showResolution");
		    this.resolutionSection = document.getElementById("resolutionContainer");
		    this.hideResolutionBtn = document.getElementById("hideResolution");
		    this.unmuteSpeakerBtn = document.getElementById("unmuteSpeaker");
		    this.muteSpeakerBtn = document.getElementById("muteSpeaker");
		    this.unmuteSpeakerBtn.style.display = "block";
		    this.muteSpeakerBtn.style.display = "none";
		    this.showResolutionBtn.style.display = "block";
		    this.hideResolutionBtn.style.display = "none";
		    this.resolutionSection.style.display = "none";
	        var playBtn = document.getElementById("playButton");
	        var pauseBtn = document.getElementById("pauseButton");
	        
	        if(fromContent == '1') {
				playBtn.style.display = "none";
	        	pauseBtn.style.display = "block";
				localStorage.setItem('fromContent', '0');
			} else {
				this.video.pause();
				setTimeout(() => {
			  		this.setState({
			          showLoader: false,
			        });
			    }, 2000);
				playBtn.style.display = "block";
	        	pauseBtn.style.display = "none";
			}
			
	  	}, 1000);

	  	setTimeout(() => {
			var objProfileSelect = document.getElementById('select');
			var h = '<option value="-1">Auto</option>';
			// fetch video playback profiles
			if(this.hlsPlayer && this.hlsPlayer.levels) {
				var trackList = this.hlsPlayer.levels;
				for (var i = 0; i < trackList.length; i++)
				{
					var t =
						'profile ' + [trackList.length - i] +
						'  @ ' + parseFloat(trackList[i].bitrate / 1024).toFixed(0) + ' kbps';
					h += '<option value=' + i + '>' + t + '</option>';
				}
				//console.log(h);
				objProfileSelect.innerHTML = h;

				var skip_first = 'true';
				var options = window.$("#select option");   
				var select_options = (skip_first) ? window.$('#select option:not(:first)') : window.$('#select option');                        
				select_options.detach().sort(function(a,b) {  
				//console.log(a.value)  
				    var at = a.value;
				    var bt = b.value;
				    return (bt > at)?1:((bt < at)?-1:0);           
				});
				select_options.appendTo("#select"); 
		}
	    }, 3000);

	  	this.seekInterval = setInterval(() => {
	  		this.runSeek();this.recalcRates();
	  	}, 1000);

	  	let controlCount = 0;
	  	this.timeout = setInterval(function() {
	  		controlCount++;
	  		if(controlCount == 8) {
	  			controlCount = 0;
	  			window.$('#top_label').slideUp(800);
	       		window.$('#rewindButton').css('visibility','hidden');
	       		/*window.$('#playButton').css('visibility','hidden');*/
	       		window.$('#pauseButton').css('visibility','hidden');
   				window.$('#fastForwardButton').css('visibility','hidden');
	       		window.$('#controls').slideUp(1000);
	  		}
	    }, 1000);	

	    this.onMouseEnterHandler = () => {
	        //console.log('enter');
	        controlCount = 0;
	        window.$('#rewindButton').css('visibility','visible');
	      	/*window.$('#playButton').css('visibility','visible');*/
	      	window.$('#pauseButton').css('visibility','visible');
	      	window.$('#fastForwardButton').css('visibility','visible');
	      	window.$('#top_label').slideDown(1000);             
	       	window.$('#controls').slideDown(1000);
	       	if(this.video.paused) {
	       		window.$('#playButton').show();
	       	} else {
	       		window.$('#playButton').hide();
	       	}
	    }

	    this.profileFocus = () => {
	    	controlCount = 0;
	    }
		} else {
			this.props.history.push('/mtab')
		}
	} else {
		this.props.history.push('/')
		window.location.reload();
	}

		
  	}

  	

  	componentDidMount() {
  		window.scrollTo(0, 0);
  		this.connectionInterval = setInterval(() => {
	  		this.handleConnectionChange();
	  	}, 1000);
  		this.video = document.getElementById('video');
  		this.seekbar = document.querySelector('.seekbar');
	    this.video.addEventListener("seeking", this.onSeeking);
	    this.video.addEventListener("pause", this.onPause);
	    this.video.addEventListener("playing", this.isPlaying);
	    this.video.addEventListener("ended", this.isEnded);
	    this.video.addEventListener("online",this.handleConnectionChange);
	    this.video.addEventListener("offline",this.handleConnectionChange);
	    this.video.addEventListener("timeupdate",this.timeupdate);
	    /*this.seekbar.addEventListener("change",this.change);*/
	    this.seekbar.addEventListener("mouseup",this.mouseup);
	    this.seekbar.addEventListener("mousedown",this.mousedown);
	    document.addEventListener("keydown",this.keydown);
  	}
  	componentWillUnmount() {
  		this.video = document.getElementById('video');
	    this.video.removeEventListener("seeking", this.onSeeking);
	    this.video.removeEventListener("pause", this.onPause);
	    this.video.removeEventListener("playing", this.isPlaying);
	    this.video.removeEventListener("ended", this.isEnded);
	    this.video.removeEventListener("online",this.handleConnectionChange);
	    this.video.removeEventListener("offline",this.handleConnectionChange);
	    this.video.removeEventListener("timeupdate",this.timeupdate);
	    /*this.seekbar.removeEventListener("change",this.change);*/
	    this.seekbar.removeEventListener("mouseup",this.mouseup);
	    this.seekbar.removeEventListener("mousedown",this.mousedown);
	    document.removeEventListener("keydown",this.keydown);
	    clearInterval(this.seekInterval);
	    clearInterval(this.timeout);
	    clearInterval(this.pausetimeout);
	    clearInterval(this.connectionInterval);
	    this.hlsPlayer.destroy();
  	}

  	/*Keyboard Changes*/
  	keydown = (event) => {

  		if(event.code == 'Space') {
  			event.preventDefault();
  			if(this.video.paused) {
  				this.video.play();
  			} else {
  				this.video.pause();
  			}
  		}

  		if(this.video.paused) {
  			let videoFrameTime = 30;
  			if(this.hlsPlayer && this.hlsPlayer.lcevcDil && this.hlsPlayer.lcevcDil.lcevcDataDetected) {
  				videoFrameTime = this.hlsPlayer.lcevcDil.frameRate;
  			}
  			videoFrameTime = 1 / videoFrameTime;
  			if(event.code == 'ArrowRight') {
  				window.$('#playButton').hide();
		        this.video.currentTime = Math.min(this.video.duration, this.video.currentTime + videoFrameTime);
		    }
		    if(event.code == 'ArrowLeft') {
		    	window.$('#playButton').hide();
		        this.video.currentTime = Math.max(0, this.video.currentTime - videoFrameTime);
		    }
  		}
  	}

  	timeupdate = () => {
		this.video = document.getElementById('video');
		this.seekbar = document.querySelector('.seekbar');
		this.seekbar.value = this.video.currentTime / this.video.duration * 100
		var v = this.seekbar.value;
	    this.seekbar.style.background = "-webkit-gradient(linear, left top, right top, color-stop(0%,#ed1e24), color-stop("+ v +"%,#ed1e24), color-stop("+ v +"%,#ddd), color-stop(100%,#ddd))";
	    this.seekbar.style.background = "-webkit-linear-gradient(left,  #ed1e24 0%,#ed1e24 "+ v +"%,#ddd "+ v +"%,#ddd 100%)";
	}

	mousedown = () => {
		//console.log('mouse down');
		this.video = document.getElementById('video');
		if(!this.video.paused) {
			//console.log('inside down')
			this.video.pause();
			this.setState({
	          seekplay: true,
	        });
		}
	}

	mouseup = () => {
		//console.log('mouse up');
		//console.log(this.state.seekplay);
		this.video = document.getElementById('video');
		if(this.state.seekplay == true) {
			this.video.play();
			this.setState({
	          seekplay: false,
	        });
		}
	}


  	/*handleConnectionChange*/
  	handleConnectionChange = () => {
  		this.video = document.getElementById('video');
  		const condition = navigator.onLine ? 'online' : 'offline';
  		if(condition == 'offline') {
  			setTimeout(() => {
		  		this.setState({
		          showLoader: true,
		        });
		    }, 1000);
		    this.video.pause();	
			this.setState({
				playingVideoPaused: true,
			});
		    var playBtn = document.getElementById("playButton");
		    var pauseBtn = document.getElementById("pauseButton");
			playBtn.style.display = "block";
          	pauseBtn.style.display = "none";
  		}
  		//console.log(this.state.playingVideoPaused);
  		if(navigator.onLine && this.state.playingVideoPaused) {
        	this.setState({
	          showLoader: false,
	        });
        }
  	}

  	/*Onseeking function*/
	  onSeeking = (event) => {
	  	this.video.muted = true;
	    if(this.video.paused) {
	  		this.setState({
	          showSeekLoader: false,
	        });
	  	} else {
	  		this.setState({
	          showSeekLoader: true,
	        });
	  	}
        
	    window.$('#playButton').hide();
	    window.$('#pauseButton').hide();
        window.$('#rewindButton').css('visibility','visible');
        window.$('#fastForwardButton').css('visibility','visible');
       	
       	if ( (this.video.readyState < this.video.HAVE_FUTURE_DATA) && this.video.paused ) {
		    window.$('#playButton').show();
		}
	  }
	/*End of Onseeking function*/

	/*OnPause function*/
	  onPause = (event) => {
	  	 window.$('#pauseButton').hide();
	  	 window.$('#playButton').show();
	  	const condition = navigator.onLine ? 'online' : 'offline';
	    if(condition == 'offline') {
	    	alert('There appears to be a problem with your internet connection. Please check connections and retry.');
	    	if (document.fullscreenElement) {
		      	document.exitFullscreen();
			}
			window.history.back();
	    }
	  	this.pausetimeout = setInterval(function() {
	       window.$('#playButton').css('visibility','visible');
	    }, 0);
	    this.setState({
          showLoader: false,
          showSeekLoader: false,
        });

	    window.$('#rewindButton').css('visibility','visible');
       	window.$('#fastForwardButton').css('visibility','visible');
	  }
	/*End of OnPause function*/

	/*IsPlaying function*/
	  isPlaying = (event) => {
	  	this.video.muted = false;
	  	window.$('#pauseButton').show();
	  	window.$('#playButton').hide();
	    this.setState({
          showLoader: false,
          showSeekLoader: false,
        });
       
       window.$('#pauseButton').css('visibility','visible');
       window.$('#rewindButton').css('visibility','visible');
       window.$('#fastForwardButton').css('visibility','visible');
	  }
	/*End of IsPlaying function*/

	/*IsEnded function*/
	  isEnded = (event) => {
        if (document.fullscreenElement) {
	      document.exitFullscreen();
		}
		this.hlsPlayer.destroy();
		window.history.back();
	  }
	/*End of IsEnded function*/

  	/*For seekbar*/
  	runSeek() {
	    this.video = document.getElementById("video");
	    //var seekbarSpan = document.getElementById("seekbarSpan");

	    var dhours = Math.floor(this.video.duration / 3600);
	    var dminutes = Math.floor((this.video.duration % 3600) / 60);
	    var dseconds = Math.floor(this.video.duration - dminutes * 60);
	    
		var phours = Math.floor(this.video.currentTime / 3600);
	    var pminutes = Math.floor((this.video.currentTime % 3600) / 60);
	    var pseconds = Math.floor(this.video.currentTime - pminutes * 60);

	    if(dhours) {
	        var dtime = ('0' + dhours).slice(-2) +':'+('0' + dminutes).slice(-2) +':'+ ('0' + dseconds).slice(-2);
	    } else if(dminutes){
	        dtime = ('0' + dminutes).slice(-2) +':'+ ('0' + dseconds).slice(-2);
	    } else {
	        dtime = ('0' + dminutes).slice(-2) +':'+ ('0' + dseconds).slice(-2);
	    }
	    
	    if(phours) {
	        var ptime = ('0' + phours).slice(-2) +':'+('0' + pminutes).slice(-2) +':'+ ('0' + pseconds).slice(-2);
	    } else {
	        ptime = ('0' + pminutes).slice(-2) +':'+ ('0' + pseconds).slice(-2);
	    } 

	    this.setState({
	    	duration: dtime,
	    	playtime:  ptime,
	    })
	    
  	}

  	/*For Video Resolution*/
	recalcRates() {
	  	this.video = document.getElementById("video");
	  	this.canvas1 = document.getElementById('canvas');
		this.d1 = document.getElementById("totalresolution");
	  	if (this.video) {

			//Video Resolution
			if(this.hlsPlayer && this.hlsPlayer.lcevcDil) {
				let fps = this.hlsPlayer.lcevcDil.frameRate || 0;
				fps = fps ? fps.toFixed(0) : 'Unknown';
				document.getElementById('frameRate').innerHTML = "Frame rate : " + fps + ' fps';
				this.d1.innerHTML = "Video Resolution : " + this.hlsPlayer.lcevcDil.frameWidth + " X " + this.hlsPlayer.lcevcDil.frameHeight
			}

		  	//hls to provide video bitrate
		  	if(this.hlsPlayer.currentLevel == '-1') {
		  		//console.log('currentLevel : -1')
		  	} else {
		  		var videoBR = this.hlsPlayer.streamController.levels[ this.hlsPlayer.currentLevel ];
		  		document.getElementById('videoBitrate').innerHTML = 'Video Bitrate : ' + (videoBR.bitrate / 1024).toLocaleString(undefined, {maximumFractionDigits:0}) + ' kbps';
		  	}
	  	}
	}

	

 render() {
 	
	const manifestURL = localStorage.getItem('manifestURL');
	const title = localStorage.getItem('title');
	const category = localStorage.getItem('category');
	const videoBaseURI = localStorage.getItem('videoBaseURI');
	const isP3Content = localStorage.getItem('isP3Content');
	const actualUrl = videoBaseURI + manifestURL;
	
	if(category == 'live'){
		this.setState({
			showLiveBnt: true,
		});
  	}

   	/**
	* Render Functions
	*/
    this.playControl = () => (event) => {
    	this.video = document.getElementById("video");
	    var playBtn = document.getElementById("playButton");
	    var pauseBtn = document.getElementById("pauseButton");
	    playBtn.style.display = "block";
	    pauseBtn.style.display = "none";
       if (this.video.paused) {
          this.video.play();
           playBtn.style.display = "none";
          pauseBtn.style.display = "block";
       } else {
          this.video.pause();
          playBtn.style.display = "block";
          pauseBtn.style.display = "none";
       }
    }

    this.goLive = () => (event) => {
    	this.video = document.getElementById('video');
    	var playBtn = document.getElementById("playButton");
	    var pauseBtn = document.getElementById("pauseButton");
      	var vidTime = this.video.duration ;
      	this.video.currentTime = vidTime;
      	this.video.play();
      	playBtn.style.display = "none";
	    pauseBtn.style.display = "block";
    }

    this.skip = (value) => (event) => {
    	this.video = document.getElementById("video");
	    var playBtn = document.getElementById("playButton");
	    var pauseBtn = document.getElementById("pauseButton");
	    this.video.currentTime += value;
	    if (this.video.paused) {
	    	this.video.pause();
          	playBtn.style.display = "block";
          	pauseBtn.style.display = "none";
       	} else {
          	this.video.play();
          	playBtn.style.display = "none";
        	pauseBtn.style.display = "block";
       	}
    }

    this.hideResolution = () => (event)  => {
	  this.showResolutionBtn.style.display = "block";
	  this.hideResolutionBtn.style.display = "none";
	  this.resolutionSection.style.display = "none";
	}
	this.showResolution = () => (event) => {
	  this.showResolutionBtn.style.display = "none";
	  this.hideResolutionBtn.style.display = "block";
	  this.resolutionSection.style.display = "block";
	}
	this.goback = () => (event) => {
		if (document.fullscreenElement) {
	      document.exitFullscreen();
		}
		/*this.hlsPlayer.destroy();*/
		window.history.back();
	}
	this.toggleFullScreen = () => (event) => {
		var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (isSafari) {
			var elem = document.getElementById('canvas');
		} else {
			var elem = document.documentElement;
		}
		 if (elem.requestFullscreen) {
	      elem.requestFullscreen();
	    } else if (elem.mozRequestFullScreen) {
	      /* Firefox */
	      elem.mozRequestFullScreen();
	    } else if (elem.webkitRequestFullscreen) {
	      /* Chrome, Safari and Opera */
	      elem.webkitRequestFullscreen();
	    } else if (elem.msRequestFullscreen) {
	      /* IE/Edge */
	      elem.msRequestFullscreen();
	    }
	    if (document.fullscreenElement) {
	      document.exitFullscreen();
		}   
	}


	this.changeProfile = (event) => {
		//console.log(this.state.hplayer);
	    var newLevel = parseInt(event.target.value);
	    //console.log(newLevel);
	    if(newLevel != "-1"){
	        this.hlsPlayer.nextLevel = newLevel;
	    } else {
	        this.hlsPlayer.nextLevel = -1;
	     }
	};

	this.setVolume = (event) => {
		this.unmuteSpeakerBtn = document.getElementById("unmuteSpeaker");
		this.muteSpeakerBtn = document.getElementById("muteSpeaker");
		var val = document.getElementById("vol-control").value;
		var player = document.getElementById('video');
		if(val == '0') {
			player.muted = true;
		    this.unmuteSpeakerBtn.style.display = "none";
		    this.muteSpeakerBtn.style.display = "block";
		} else {
			player.muted = false;
		    this.unmuteSpeakerBtn.style.display = "block";
		    this.muteSpeakerBtn.style.display = "none";
		}
		
	    //console.log('Before: ' + player.volume);
	    player.volume = val / 100;
	    //console.log('After: ' + player.volume);
	};

	this.toggleVolume = () => (event) => {
		this.video = document.getElementById("video");
		var unmuteSpeakerBtn = document.getElementById("unmuteSpeaker");
		var muteSpeakerBtn = document.getElementById("muteSpeaker");
		var val = document.getElementById("vol-control").value;
		if(this.video.muted) {
			this.video.volume = val / 100;
			this.video.muted = false;
			unmuteSpeakerBtn.style.display = "block";
		    muteSpeakerBtn.style.display = "none";
		} else {
			val = 0;
			this.video.volume = val / 100;
			this.video.muted = true;
			unmuteSpeakerBtn.style.display = "none";
		    muteSpeakerBtn.style.display = "block";
		}
	}

	this.changeSeekbar = (event) => {
		event.preventDefault();
    	this.video = document.getElementById('video');
		this.seekbar = document.querySelector('.seekbar');
		this.video.currentTime = (event.target.value * this.video.duration) / 100;
		var v = this.seekbar.value;
		this.seekbar.style.background = "-webkit-gradient(linear, left top, right top, color-stop(0%,#ed1e24), color-stop("+ v +"%,#ed1e24), color-stop("+ v +"%,#ddd), color-stop(100%,#ddd))";
	    this.seekbar.style.background = "-webkit-linear-gradient(left,  #ed1e24 0%,#ed1e24 "+ v +"%,#ddd "+ v +"%,#ddd 100%)";
    }

   return (

	<div className="pplayercontainer" onMouseMove={this.onMouseEnterHandler}>

		<div className="clickable pclickableContainer">
			<video id="video" className="pvideotag"></video>
			<canvas id="canvas" className="pcanvastag"></canvas>
			<div id="player-controls"></div>
		</div>
		<div>
			{
				(isP3Content == '0') ? (
					<span></span>
				) : ( <img id="lcevc_label" className="plcevclabel" src={lcevc} /> )
			}
            <img onClick={this.skip(-10)} id="rewindButton" className="material-icons prewindicon" src={rewind} alt="fast_rewind" />
            <div className="playVideo" onClick={this.playControl()}>
            	{ 
		          (this.state.showPlayButton)?( 
		          	<img className="pplaypauseicon" id="playButton" src={play} alt="play" /> 
		          ) : ( <span></span> ) 
		        }
	           
	           <img className="pplaypauseicon" id="pauseButton" src={pause} alt="pause" />
            </div>
            <img onClick={this.skip(10)} className="material-icons pforwardicon" src={forward} alt="fast_forward" id="fastForwardButton" />       
	    </div>

		<div id="resolutionContainer" className="presolutionContainer">
		   <div className="presolutioncontent">
		       <div id="totalresolution">Video Resolution : </div>
		       <div id="videoBitrate"> </div>
		       <div id="audioBitrate"> </div>
		       <div id="frameRate"> </div>
		   </div>
		</div>

		<div id="top_label" className="ptoplabel">
			<span>&nbsp;&nbsp;<img id="goback" onClick={this.goback()} className="pplayergoback" src={back} />&nbsp;&nbsp;</span>
	       {/*<label className="ptoplabeltitle">powered by V-Nova</label>*/}
	      
	       <div className="ptoplabelcontainer">

	       	<select className="select select_player pprofilesettings" id="select"  onFocus={this.profileFocus} onChange={this.changeProfile}>
				<option value="-1">Auto</option>
			</select>

	      	{ 
	          (this.state.showHideResolutionBtn)?( 
	          	<img id="hideResolution" onClick={this.hideResolution()} className="presoultionbtn" src={select} />
	          ) : ( <span></span> ) 
	        }
	        
	        <img id="showResolution" onClick={this.showResolution()} className="presoultionbtn" src={unselect} />
	        
	        {/*<img id="unmuteSpeaker" onClick={this.toggleVolume()} className="pvolumebtn" src={unMute} />
	        <img id="muteSpeaker" onClick={this.toggleVolume()} className="pvolumebtn" src={mute} />*/}

	          <div id="fullscreenButton" onClick={this.toggleFullScreen()} className="material-icons pfullscreenbtn">
	            <img src={expand} alt="fullscreen" className="width_20" />
	          </div>
	        </div>
	    </div>

	    <div id="controls" className="pseekbarcontrols">
	        <div className="pseekbarcontainer">
	        	<div className="volume_control_container">
			        <img id="unmuteSpeaker" onClick={this.toggleVolume()} className="pvolumebtn" src={unMute} alt="Volume" />
			        <img id="muteSpeaker" onClick={this.toggleVolume()} className="pvolumebtn" src={mute} alt="Volume" />
			        <div className="vol-control">
		              <input id="vol-control" type="range" min="0" max="100" step="1" onChange={this.setVolume}></input>
		            </div>
		        </div>
	        	{ 
			        (this.state.showLiveBnt)?(
			            <span></span>
			        ) : ( <span id="playTime">{this.state.playtime}</span> ) 
		        }
	            
	            <input id="playerSeekbar" className="seekbar player_seekbar_input seekbar_input_controls" type="range" onChange={this.changeSeekbar}></input>

	            {/*<div className="pseekbar" id="custom-seekbar" onClick={this.customSeekbar()}>
	               <span id="seekbarSpan" className="pseekbarspan"></span>
	            </div>*/}

	            { 
			        (this.state.showLiveBnt)?(
			            <span></span>
			        ) : ( <span>
			        	{
			        		(this.state.duration != 'aN:aN')?(<span id="durationTime">{this.state.duration}</span>):(<span></span>)
			        	}
			        	

			        </span> ) 
		        }

	            { 
		          (this.state.showLiveBnt)?( 
		          	<span><button id="livebtn"onClick={this.goLive()} className="livebtn">Live</button></span>
		          ) : ( <span></span> ) 
		        }
	            
	        </div>

	    </div>

	    <div> 
	        { 
	          (this.state.showLoader)?( 
	          	<div id="loading"></div>
	          ) : ( <span></span> ) 
	        }
	    </div>

	    <div> 
	        { 
	          (this.state.showSeekLoader)?( 
	          	<div id="loading_seek"></div>
	          ) : ( <span></span> ) 
	        }
	    </div>
	   
	</div>

      );
   }
}

export default HLSPlayer;