import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import logo from './images/logo.png';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

class ErrorNotFound extends React.Component {
 

  constructor(props) {
    super(props);
    this.state = {   
 	};
 	  this.onInit();
  }

  onInit() {
    var userName = localStorage.getItem('userName');
  	//alert('Url Not Found');
    if(!userName) {
      this.props.history.push('/')
      window.location.reload();
    } else {
      this.props.history.push('/mtab')
    }
  	
  }


 render() {
   return (
      <div className="container-fluid">
      </div>   
      );
   }

}

export default ErrorNotFound;