import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import back from './images/goback.png';
import play from './images/1.png';
import pause from './images/2.png';
import rewind from './images/3.png';
import forward from './images/4.png';
import select from './images/select.png';
import unselect from './images/unselect.png';
import expand from './images/expand.png';
import unMute from './images/volume_full.png';
import mute from './images/volume_off.png';
import lcevc from './images/lcevc.png';
import lcevcToggle from './images/lcevc_toggle.png';

class TogglePlayer extends React.Component {

   constructor(props) {
    super(props);
    var self = this;
    this.state = {
      	showSeekLoader:false,
      	showLiveBnt:false,
      	showHideResolutionBtn: false,
      	showPlayButton: false,
      	playingVideoPaused: false,
      	duration: '',
      	timeout: '',
      	streamUrl: '',
      	seekplay: false,
      	trackListLength : false,
      	videoStarted : false,
      	lcevcIsReady : true,
      	loopVideo: false,
 	  };
 	this.onInit();
 	this.changeStreamUrl = this.changeStreamUrl.bind(this);
  }

  	onInit() {

  		localStorage.setItem('routefromTogglePlayer', 'fromTogglePlayer');

		var interval = setTimeout(() => {
			
			this.videoContainer = document.getElementById('player');
			this.pplayercontainer = document.getElementById('lcevc_player_pplayercontainer');
			this.video = document.getElementById('video');
			this.canvas = document.getElementById('canvas');

	      	this.setState({
	          showHideResolutionBtn: true,
	          showPlayButton: true,
	          lcevcIsReady: true,
          	  loopVideo: false,
	        });

		    this.showResolutionBtn = document.getElementById("showResolution");
		    this.resolutionSection = document.getElementById("resolutionContainer");
		    this.hideResolutionBtn = document.getElementById("hideResolution");
		    this.unmuteSpeakerBtn = document.getElementById("unmuteSpeaker");
		    this.muteSpeakerBtn = document.getElementById("muteSpeaker");
		    this.unmuteSpeakerBtn.style.display = "none";
		    this.muteSpeakerBtn.style.display = "none";
		    this.showResolutionBtn.style.display = "none";
		    this.hideResolutionBtn.style.display = "none";
		    this.resolutionSection.style.display = "none";
	        var playBtn = document.getElementById("playButton");
	        var pauseBtn = document.getElementById("pauseButton");
	        var rewindBtn= document.getElementById("rewindButton");
	        var fastForwardBtn = document.getElementById("fastForwardButton");
	        playBtn.style.display = "none";
	       	pauseBtn.style.display = "none";
	       	rewindBtn.style.display = "none";
	       	fastForwardBtn.style.display = "none";
	       	window.$('#top_label').css('visibility','hidden');
       		window.$('#controls').css('visibility','hidden');
       		window.$('#lcevc_label').css('visibility','hidden');
			
	  	}, 500);


	  	this.seekInterval = setInterval(() => {
	  		this.runSeek();this.recalcRates();this.playerResize();
	  	}, 1000);

	  	let controlCount = 0;
	  	this.timeout = setInterval(function() {
	  		controlCount++;
	  		if(controlCount == 8) {
	  			controlCount = 0;
	  			window.$('#top_label').slideUp(800);
	       		window.$('#rewindButton').css('visibility','hidden');
	       		/*window.$('#playButton').css('visibility','hidden');*/
	       		window.$('#pauseButton').css('visibility','hidden');
   				window.$('#fastForwardButton').css('visibility','hidden');
	       		window.$('#controls').slideUp(1000);
	  		}
	    }, 1000);	

	    this.onMouseEnterHandler = () => {
	        //console.log('enter');
	        controlCount = 0;
	        window.$('#rewindButton').css('visibility','visible');
	      	/*window.$('#playButton').css('visibility','visible');*/
	      	window.$('#pauseButton').css('visibility','visible');
	      	window.$('#fastForwardButton').css('visibility','visible');
	      	window.$('#top_label').slideDown(1000);             
	       	window.$('#controls').slideDown(1000);
	       	if(this.video.paused) {
	       		window.$('#playButton').show();
	       	} else {
	       		window.$('#playButton').hide();
	       	}
	    }

	    this.profileFocus = () => {
	    	controlCount = 0;
	    }
		
  	}

  	changeStreamUrl(event){
        this.setState(
            {streamUrl : event.target.value}
        );
    }

  	componentDidMount() {
  		window.scrollTo(0, 0);
  		this.connectionInterval = setInterval(() => {
	  		this.handleConnectionChange();
	  	}, 1000);
  		this.video = document.getElementById('video');
  		this.seekbar = document.querySelector('.seekbar');
	    this.video.addEventListener("seeking", this.onSeeking);
	    this.video.addEventListener("pause", this.onPause);
	    this.video.addEventListener("playing", this.isPlaying);
	    this.video.addEventListener("ended", this.isEnded);
	    this.video.addEventListener("online",this.handleConnectionChange);
	    this.video.addEventListener("offline",this.handleConnectionChange);
	    this.video.addEventListener("timeupdate",this.timeupdate);
	    /*this.seekbar.addEventListener("change",this.change);*/
	    this.seekbar.addEventListener("mouseup",this.mouseup);
	    this.seekbar.addEventListener("mousedown",this.mousedown);
	    document.addEventListener("keydown",this.keydown);
  	}
  	componentWillUnmount() {
  		this.video = document.getElementById('video');
	    this.video.removeEventListener("seeking", this.onSeeking);
	    this.video.removeEventListener("pause", this.onPause);
	    this.video.removeEventListener("playing", this.isPlaying);
	    this.video.removeEventListener("ended", this.isEnded);
	    this.video.removeEventListener("online",this.handleConnectionChange);
	    this.video.removeEventListener("offline",this.handleConnectionChange);
	    this.video.removeEventListener("timeupdate",this.timeupdate);
	    /*this.seekbar.removeEventListener("change",this.change);*/
	    this.seekbar.addEventListener("mouseup",this.mouseup);
	    this.seekbar.addEventListener("mousedown",this.mousedown);
	    document.removeEventListener("keydown",this.keydown);
	    clearInterval(this.seekInterval);
	    clearInterval(this.timeout);
	    clearInterval(this.pausetimeout);
	    clearInterval(this.connectionInterval);
	    if(window.player.assetUri_) {
	    	window.player.destroy();
	    }
  	}

  	/*Keyboard Changes*/
  	keydown = (event) => {
  		this.video = document.getElementById("video");
	    var playBtn = document.getElementById("playButton");
	    var pauseBtn = document.getElementById("pauseButton");
  		if(event.code == 'Space') {
  			event.preventDefault();
  			if(this.video.paused) {
  				this.video.play();
  				playBtn.style.display = "none";
          		pauseBtn.style.display = "block";
  			} else {
  				this.video.pause();
  				playBtn.style.display = "block";
          		pauseBtn.style.display = "none";
  			}
  		}
  	}

  	timeupdate = () => {
		this.video = document.getElementById('video');
		this.seekbar = document.querySelector('.seekbar');
		this.seekbar.value = this.video.currentTime / this.video.duration * 100
		var v = this.seekbar.value;
	    this.seekbar.style.background = "-webkit-gradient(linear, left top, right top, color-stop(0%,#ed1e24), color-stop("+ v +"%,#ed1e24), color-stop("+ v +"%,#ddd), color-stop(100%,#ddd))";
	    this.seekbar.style.background = "-webkit-linear-gradient(left,  #ed1e24 0%,#ed1e24 "+ v +"%,#ddd "+ v +"%,#ddd 100%)";
	}

	mousedown = () => {
		//console.log('mouse down');
		this.video = document.getElementById('video');
		if(!this.video.paused) {
			//console.log('inside down')
			this.video.pause();
			this.setState({
	          seekplay: true,
	        });
		}
	}

	mouseup = () => {
		//console.log('mouse up');
		//console.log(this.state.seekplay);
		this.video = document.getElementById('video');
		if(this.state.seekplay == true) {
			this.video.play();
			this.setState({
	          seekplay: false,
	        });
		}
	}

  	/*handleConnectionChange*/
  	handleConnectionChange = () => {
  		this.video = document.getElementById('video');
  		const condition = navigator.onLine ? 'online' : 'offline';
  		if(condition == 'offline') {
  			setTimeout(() => {
		  		this.setState({
		          showLoader: true,
		        });
		    }, 1000);
		    this.video.pause();	
			this.setState({
				playingVideoPaused: true,
			});
		    var playBtn = document.getElementById("playButton");
		    var pauseBtn = document.getElementById("pauseButton");
			playBtn.style.display = "block";
          	pauseBtn.style.display = "none";
  		}
  		//console.log(this.state.playingVideoPaused);
  		if(navigator.onLine && this.state.playingVideoPaused) {
        	this.setState({
	          showLoader: false,
	        });
        }
  	}

  	/*Onseeking function*/
	  onSeeking = (event) => {
	  	this.video.muted = true;
	    if(this.video.paused) {
	  		this.setState({
	          showSeekLoader: false,
	        });
	  	} else {
	  		if(this.state.loopVideo) {
	  			this.setState({
		          showLoader: false,
		          showSeekLoader: false,
		        });
	  		} else {
	  			this.setState({
		          showSeekLoader: true,
		        });
	  		}
	  	}

	  	/*if(this.state.lcevcIsReady) {
        	var path = parseInt(1, 10);
			window.player.lcevcDil.setConfigOption('shaderPath', path);
			//this.video.dispatchEvent(new Event('render'));
			if (window.player && window.player.lcevc) {
		      window.player.lcevcDil.videoEvent({ type: 'seeked' });
		    }
        } else {
        	var path = parseInt(0, 10);
			window.player.lcevcDil.setConfigOption('shaderPath', path);
			//this.video.dispatchEvent(new Event('render'));
			if (window.player && window.player.lcevc) {
		      window.player.lcevcDil.videoEvent({ type: 'seeked' });
		    }
        }*/
        
	    window.$('#playButton').hide();
	    window.$('#pauseButton').hide();
        window.$('#rewindButton').css('visibility','visible');
        window.$('#fastForwardButton').css('visibility','visible');
       	
       	if ( (this.video.readyState < this.video.HAVE_FUTURE_DATA) && this.video.paused ) {
		    window.$('#playButton').show();
		}
	  }
	/*End of Onseeking function*/

	/*OnPause function*/
	  onPause = (event) => {
	  	 window.$('#pauseButton').hide();
	  	 window.$('#playButton').show();
	  	const condition = navigator.onLine ? 'online' : 'offline';
	    if(condition == 'offline') {
	    	alert('There appears to be a problem with your internet connection. Please check connections and retry.');
	    	if (document.fullscreenElement) {
		      	document.exitFullscreen();
			}
			window.location.reload();
	    }
	  	this.pausetimeout = setInterval(function() {
	       window.$('#playButton').css('visibility','visible');
	    }, 0);
	    this.setState({
          showLoader: false,
          showSeekLoader: false,
        });

	    window.$('#rewindButton').css('visibility','visible');
       	window.$('#fastForwardButton').css('visibility','visible');
	  }
	/*End of OnPause function*/

	/*IsPlaying function*/
	  isPlaying = (event) => {
	  	this.video.muted = false;
	  	window.$('#pauseButton').show();
	  	window.$('#playButton').hide();
	    this.setState({
          showLoader: false,
          showSeekLoader: false,
        });
       
       window.$('#pauseButton').css('visibility','visible');
       window.$('#rewindButton').css('visibility','visible');
       window.$('#fastForwardButton').css('visibility','visible');
	  }
	/*End of IsPlaying function*/

	/*IsEnded function*/
	  isEnded = (event) => {
        /*if (document.fullscreenElement) {
	      document.exitFullscreen();
		}*/
		this.setState({
			loopVideo: true,
		});
		this.video.currentTime = 0;
		this.video.play();
		//window.player.destroy();
		//window.history.back();
	  }
	/*End of IsEnded function*/

  	/*For seekbar*/
  	runSeek() {
	    this.video = document.getElementById("video");

	    var dhours = Math.floor(this.video.duration / 3600);
	    var dminutes = Math.floor((this.video.duration % 3600) / 60);
	    var dseconds = Math.floor(this.video.duration - dminutes * 60);
	    
		var phours = Math.floor(this.video.currentTime / 3600);
	    var pminutes = Math.floor((this.video.currentTime % 3600) / 60);
	    var pseconds = Math.floor(this.video.currentTime - pminutes * 60);

	    if(dhours) {
	        var dtime = ('0' + dhours).slice(-2) +':'+('0' + dminutes).slice(-2) +':'+ ('0' + dseconds).slice(-2);
	    } else if(dminutes){
	        dtime = ('0' + dminutes).slice(-2) +':'+ ('0' + dseconds).slice(-2);
	    } else {
	        dtime = ('0' + dminutes).slice(-2) +':'+ ('0' + dseconds).slice(-2);
	    }
	    
	    if(phours) {
	        var ptime = ('0' + phours).slice(-2) +':'+('0' + pminutes).slice(-2) +':'+ ('0' + pseconds).slice(-2);
	    } else {
	        ptime = ('0' + pminutes).slice(-2) +':'+ ('0' + pseconds).slice(-2);
	    } 

	    this.setState({
	    	duration: dtime,
	    	playtime:  ptime,
	    })
  	}

  	/*For Video Resolution*/
	recalcRates() {
	  	this.video = document.getElementById("video");
	  	this.canvas1 = document.getElementById('canvas');
		this.d1 = document.getElementById("totalresolution");
	  	if (this.video) {
			//Video Resolution
			if(window.player && window.player.lcevcDil) {
				let fps = window.player.lcevcDil.frameRate || 0;
				fps = fps ? fps.toFixed(0) : 'Unknown';
				document.getElementById('frameRate').innerHTML = "Frame rate : " + fps + ' fps';
				this.d1.innerHTML = "Video Resolution : " + window.player.lcevcDil.frameWidth + " X " + window.player.lcevcDil.frameHeight
			}

			/*console.log(window.player);
			console.log(window.player.currentLevel);*/
			//hls to provide video bitrate
		  	/*if(window.player.currentLevel == '-1') {
		  		//console.log('currentLevel : -1')
		  	} else {
		  		var videoBR = window.player.streamController.levels[ window.player.currentLevel ];
		  		document.getElementById('videoBitrate').innerHTML = 'Video Bitrate : ' + (videoBR.bitrate / 1024).toLocaleString(undefined, {maximumFractionDigits:0}) + ' kbps';
		  	}*/

	  	}
	}

	playerResize() {
        const aspectRatio =
          (window.player.lcevcDil && window.player.lcevcDil.aspectRatio > 0
            ? window.player.lcevcDil.aspectRatio
            : this.video.videoWidth / this.video.videoHeight) || 1920 / 1080;
        this.videoContainer.style.width = `${this.videoContainer.parentElement.offsetWidth}px`;
        

        if (window.player && window.player.lcevcDil && window.player.lcevcDil.isFullscreen) {
          //this.videoContainer.style.height = `${window.innerHeight}px`;

          if (this.canvas.height < this.videoContainer.offsetHeight) {
            this.canvas.style.top = `${(window.innerHeight - this.canvas.height) / 2}px`;
          } else {
            this.canvas.style.top = '';
            this.canvas.style.height = `${window.innerHeight}px`;
          }
        } else {
          //this.videoContainer.style.height = `${this.videoContainer.parentElement.offsetWidth / aspectRatio}px`;

          this.canvas.style.height = `${this.videoContainer.offsetHeight}px`;
          this.canvas.style.top = '';
          	if( (this.videoContainer.offsetWidth > this.videoContainer.offsetHeight) ) {
	            var resizePlayerWidth = `${this.videoContainer.offsetHeight * aspectRatio}`;
	            
	            if (this.videoContainer.offsetWidth > resizePlayerWidth) {
		            this.videoContainer.style.width = `${this.videoContainer.offsetHeight * aspectRatio}px`;
		        }
          	}
          /*if( (this.videoContainer.parentElement.offsetWidth >'992') && (this.videoContainer.offsetWidth > this.videoContainer.offsetHeight) ) {

            this.videoContainer.style.width = `${this.videoContainer.offsetHeight * aspectRatio}px`;

          }*/
        }
        
        /*if(this.pplayercontainer.offsetWidth != this.videoContainer.offsetWidth) {
        	//console.log('WIDTH CHANGE')
        	console.log(this.pplayercontainer.offsetWidth - this.videoContainer.offsetWidth);
        }*/

		if(this.pplayercontainer.offsetHeight != this.videoContainer.offsetHeight) {
        	this.videoContainer.style.position = 'absolute';
        	this.videoContainer.style.top = (this.pplayercontainer.offsetHeight - this.videoContainer.offsetHeight)/2 + 'px';
        	//console.log(this.videoContainer.style.top);
        	//console.log(this.pplayercontainer.offsetHeight - this.videoContainer.offsetHeight);
        } else {
        	this.videoContainer.style.position = 'relative';
        	this.videoContainer.style.top = '0px';
        }
        
      }
	

 render() {

   	/**
	* Render Functions
	*/

	this.startPlay = () => {


		if(this.state.streamUrl) {

			const condition = navigator.onLine ? 'online' : 'offline';
			if (condition == 'offline') {
				alert('There appears to be a problem with your internet connection. Please check connections and retry.');
			} else {

				this.setState({
		          showLoader: true,
		          videoStarted: true,
		          loopVideo: false,
		        });

				this.showResolutionBtn = document.getElementById("showResolution");
			    this.resolutionSection = document.getElementById("resolutionContainer");
			    this.hideResolutionBtn = document.getElementById("hideResolution");
			    this.unmuteSpeakerBtn = document.getElementById("unmuteSpeaker");
			    this.muteSpeakerBtn = document.getElementById("muteSpeaker");
			    this.unmuteSpeakerBtn.style.display = "block";
			    this.muteSpeakerBtn.style.display = "none";
			    this.showResolutionBtn.style.display = "block";
			    this.hideResolutionBtn.style.display = "none";
			    this.resolutionSection.style.display = "none";
		        var playBtn = document.getElementById("playButton");
		        var pauseBtn = document.getElementById("pauseButton");
		        var rewindBtn= document.getElementById("rewindButton");
		        var fastForwardBtn = document.getElementById("fastForwardButton");
		        playBtn.style.display = "none";
		       	pauseBtn.style.display = "block";
		       	rewindBtn.style.display = "block";
		       	fastForwardBtn.style.display = "block";
		       	window.$('#top_label').css('visibility','visible');
		   		window.$('#controls').css('visibility','visible');
		   		window.$('#lcevc_label').css('visibility','visible');

		   		window.$('.lcevc_player_pplayercontainer').css('visibility','visible');

		   		let player = null;

  				window.shaka.polyfill.installAll();
				
				this.video = document.getElementById('video');
				this.canvas = document.getElementById('canvas');

				// Clone and replace video element.
			    const video = this.video.cloneNode(false);
			    this.video.replaceWith(video);
			    this.video = video;

			    // Clone and replace canvas element.
			    const canvas = this.canvas.cloneNode(false);
			    this.canvas.replaceWith(canvas);
			    this.canvas = canvas;


			     // Check to see if the browser supports the basic APIs Shaka needs.
			    if (!window.shaka.Player.isBrowserSupported()) {
			      // This browser does not have the minimum set of APIs we need.
			      throw new Error('Browser not supported!');
			    }

			    if (player) {
			      if (player.lcevcDil) {
			        player.lcevcDil.close();
			      }
			      player.destroy();
			      player = null;
			    }
			    player = new window.shaka.Player(this.video);

			    // For LCEVC Decode on Safari - macOS and Safari - padOS
			    player.configure('streaming.useNativeHlsOnSafari', false);
				// For LCEVC Decode on Edge for TS chunks
			    player.configure('streaming.forceTransmuxTS', true);
			    // eslint-disable-next-line no-undef
			    setMediaSource(player);


			    if(this.state.lcevcIsReady) {
		    		var shaderVal = 1;
		    	} else {
		    		var shaderVal = 0;
		    	}


			    const lcevcDilConfig = {
			      shaderPath: shaderVal,
			      logLevel: 0,
			      dps: false,
			      playerControls: {
			        controlsID: 'player-controls',
			        enabled: false,
			      }
			    };

			    player.attachLcevc(this.video, this.canvas, lcevcDilConfig);
			    // Attach player to the window to make it easy to access in the JS console.
	    		window.player = player;

	    		window.player.addEventListener('variantchanged', this.onVariantChange);
    			window.player.addEventListener('adaptation', this.onVariantChange);

			    // Try to load a manifest.
			    // This is an asynchronous process.
			    player.load(this.state.streamUrl).then(() => {
			      // This runs if the asynchronous load is successful.
			      console.info('The video has now been loaded!'); // eslint-disable-line
			      	setTimeout(() => {
			          this.video.play();
			          this.onVariantChange();
			        },1000);
			    }).catch(this.onError); // onError is executed if the asynchronous load fails.

			    
			    this.video.addEventListener("seeking", this.onSeeking);
			    //this.video.addEventListener("pause", this.onPause);
			    this.video.addEventListener("playing", this.isPlaying);
			    this.video.addEventListener("ended", this.isEnded);
			    this.video.addEventListener("timeupdate",this.timeupdate);
			}

			/*setTimeout(() => {
				if(this.state.lcevcIsReady) {
		        	var path = parseInt(1, 10);
					window.player.lcevcDil.setConfigOption('shaderPath', path);
					//this.video.dispatchEvent(new Event('render'));
					if (window.player && window.player.lcevc) {
				      window.player.lcevcDil.videoEvent({ type: 'seeked' });
				    }
		        } else {
		        	var path = parseInt(0, 10);
					window.player.lcevcDil.setConfigOption('shaderPath', path);
					//this.video.dispatchEvent(new Event('render'));
					if (window.player && window.player.lcevc) {
				      window.player.lcevcDil.videoEvent({ type: 'seeked' });
				    }
		        }
	        }, 1000);*/
			
		} else {
			alert('Enter the manifest URL.');
			window.$("#stream-url").focus();
		}


	}/*end of startPlay function*/

	this.onError = (error) => {
	  console.error('Error code', error.code, 'object', error);
	  	setTimeout(() => {
	  		//window.history.back();
	  		window.location.reload();
	    }, 1000);
	    if(error.code == '4032') {
	   		alert('Stream is not supported by the Shaka player.');
	   	} else {
	   		alert('Something went wrong, Please try again.');
	   	}
	}


	this.onVariantChange = () => {
		const trackList = window.player.getVariantTracks();
	    let containerFormat = 0;
		let iterator = 0;
		for (iterator = 0; iterator < trackList.length; iterator += 1) {
		  if (trackList[iterator].active) {
		    if (trackList[iterator].mimeType === 'video/webm') {
		      containerFormat = 1;
		    }
		    if (trackList[iterator].mimeType === 'video/mp4') {
		      containerFormat = 2;
		    }
		    this.currentVariant = iterator;
		    window.player.updateLevel(iterator, containerFormat);
		    break;
		  }
		}
  		// const objProfileSelect = document.getElementById('select');
	   //  let h = '<option value="-1">Auto</option>';
	   //  // Fetch video playback profiles.
	   //  const trackList = window.player.getVariantTracks();
	   //  if (window.player && window.player.streamingEngine_.currentVariant_.video) {
	   //    	for (var i = 0; i < trackList.length; i++)
				// {
				// 	var t =
				// 		'profile ' + [i + 1] +
				// 		'  @ ' + parseFloat(trackList[i].bandwidth / 1024).toFixed(0) + ' kbps';
				// 	h += '<option value=' + i + '>' + t + '</option>';
				// }
				// //console.log(h);
				// objProfileSelect.innerHTML = h;
	   //  }
  	}

    this.playControl = () => (event) => {
    	this.video = document.getElementById("video");
	    var playBtn = document.getElementById("playButton");
	    var pauseBtn = document.getElementById("pauseButton");
	    playBtn.style.display = "block";
	    pauseBtn.style.display = "none";
       if (this.video.paused) {
          this.video.play();
           playBtn.style.display = "none";
          pauseBtn.style.display = "block";
       } else {
          this.video.pause();
          playBtn.style.display = "block";
          pauseBtn.style.display = "none";
       }
    }

    this.skip = (value) => (event) => {
    	this.video = document.getElementById("video");
	    var playBtn = document.getElementById("playButton");
	    var pauseBtn = document.getElementById("pauseButton");
	    this.video.currentTime += value;
	    if (this.video.paused) {
	    	this.video.pause();
          	playBtn.style.display = "block";
          	pauseBtn.style.display = "none";
       	} else {
          	this.video.play();
          	playBtn.style.display = "none";
        	pauseBtn.style.display = "block";
       	}
    }

    this.hideResolution = () => (event)  => {
	  this.showResolutionBtn.style.display = "block";
	  this.hideResolutionBtn.style.display = "none";
	  this.resolutionSection.style.display = "none";
	}
	this.showResolution = () => (event) => {
	  this.showResolutionBtn.style.display = "none";
	  this.hideResolutionBtn.style.display = "block";
	  this.resolutionSection.style.display = "block";
	}

	this.goback = () => (event) => {
		if (document.fullscreenElement) {
	      document.exitFullscreen();
		}
		window.history.back();
	}

	this.toggleFullScreen = () => (event) => {
		var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (isSafari) {
			var elem = document.getElementById('canvas');
		} else {
			var elem = document.getElementById('lcevc_player_pplayercontainer');
		}
		 if (elem.requestFullscreen) {
	      elem.requestFullscreen();
	    } else if (elem.mozRequestFullScreen) {
	      /* Firefox */
	      elem.mozRequestFullScreen();
	    } else if (elem.webkitRequestFullscreen) {
	      /* Chrome, Safari and Opera */
	      elem.webkitRequestFullscreen();
	    } else if (elem.msRequestFullscreen) {
	      /* IE/Edge */
	      elem.msRequestFullscreen();
	    }
	    if (document.fullscreenElement) {
	      document.exitFullscreen();
		}   
	}


	this.changeProfile = (event) => {
		if (document.getElementById('select').value === '-1') {
	      window.player.configure({ abr: { enabled: true } });
	    } else {
	      const track = window.player.getVariantTracks()[document.getElementById('select').value];
	      window.player.configure({ abr: { enabled: false } });
	      window.player.selectVariantTrack(track, false, 0);
	    }
	};

	this.setVolume = (event) => {
		this.unmuteSpeakerBtn = document.getElementById("unmuteSpeaker");
		this.muteSpeakerBtn = document.getElementById("muteSpeaker");
		var val = document.getElementById("vol-control").value;
		var player = document.getElementById('video');
		if(val == '0') {
			player.muted = true;
		    this.unmuteSpeakerBtn.style.display = "none";
		    this.muteSpeakerBtn.style.display = "block";
		} else {
			player.muted = false;
		    this.unmuteSpeakerBtn.style.display = "block";
		    this.muteSpeakerBtn.style.display = "none";
		}
		
	    //console.log('Before: ' + player.volume);
	    player.volume = val / 100;
	    //console.log('After: ' + player.volume);
	};

	this.toggleVolume = () => (event) => {
		this.video = document.getElementById("video");
		var unmuteSpeakerBtn = document.getElementById("unmuteSpeaker");
		var muteSpeakerBtn = document.getElementById("muteSpeaker");
		var val = document.getElementById("vol-control").value;
		//console.log(val);
		if(this.video.muted) {
			this.video.volume = val / 100;
			//console.log(this.video.volume);
			this.video.muted = false;
			unmuteSpeakerBtn.style.display = "block";
		    muteSpeakerBtn.style.display = "none";
		} else {
			//console.log('in mute');
			val = 0;
			this.video.volume = val / 100;
			//console.log(this.video.volume);
			this.video.muted = true;
			unmuteSpeakerBtn.style.display = "none";
		    muteSpeakerBtn.style.display = "block";
		}
	}

	this.changeSeekbar = (event) => {
		event.preventDefault();
    	this.video = document.getElementById('video');
		this.seekbar = document.querySelector('.seekbar');
		this.video.currentTime = (event.target.value * this.video.duration) / 100;
		var v = this.seekbar.value;
		this.seekbar.style.background = "-webkit-gradient(linear, left top, right top, color-stop(0%,#ed1e24), color-stop("+ v +"%,#ed1e24), color-stop("+ v +"%,#ddd), color-stop(100%,#ddd))";
	    this.seekbar.style.background = "-webkit-linear-gradient(left,  #ed1e24 0%,#ed1e24 "+ v +"%,#ddd "+ v +"%,#ddd 100%)";
    }

    this.handleLcevcChange = (event) => {
    	this.setState({
          lcevcIsReady: event.target.checked,
        });

        if(event.target.checked) {
        	var path = parseInt(1, 10);
			window.player.lcevcDil.setConfigOption('shaderPath', path);
			//this.video.dispatchEvent(new Event('render'));
			if (window.player && window.player.lcevc) {
		      window.player.lcevcDil.videoEvent({ type: 'seeked' });
		    }
        } else {
        	var path = parseInt(0, 10);
			window.player.lcevcDil.setConfigOption('shaderPath', path);
			//this.video.dispatchEvent(new Event('render'));
			if (window.player && window.player.lcevc) {
		      window.player.lcevcDil.videoEvent({ type: 'seeked' });
		    }
        }
	}

   return (

	<div style={{backgroundColor: '#000'}}>

		<div className="lcevc_player_container">
			<span><img id="goback" onClick={this.goback()} className="lcevc_player_goback" src={back} /></span>
			<span>&emsp;</span>
			<input type="text" className="form-control lcevc_player_url_input" id="stream-url" name="text"
				value={this.state.streamUrl} placeholder="Enter the manifest URL" onChange = {this.changeStreamUrl} />
			<span>&emsp;</span>
			<label type="button" className="lcevc_player_url_btn" onClick={this.startPlay}>Load</label>
		</div>

		<div id="lcevc_player_pplayercontainer" className="lcevc_player_pplayercontainer" onMouseMove={this.onMouseEnterHandler}>

		<div id="player" className="clickable pclickableContainer">
			<video id="video"></video>
			<canvas id="canvas"></canvas>
			<div id="player-controls"></div>
		</div>
		<div>
			{ 
	          (window.player && window.player.lcevcDil && this.state.lcevcIsReady)?( 
	          	<img id="lcevc_label" className="lcevc_player_plcevclabel" src={lcevc} />
	          ) : ( <span></span> ) 
	        }
            <img onClick={this.skip(-10)} id="rewindButton" className="material-icons prewindicon" src={rewind} alt="fast_rewind" />
            <div className="playVideo" onClick={this.playControl()}>
            	{ 
		          (this.state.showPlayButton)?( 
		          	<img className="pplaypauseicon" id="playButton" src={play} alt="play" /> 
		          ) : ( <span></span> ) 
		        }
	           
	           <img className="pplaypauseicon" id="pauseButton" src={pause} alt="pause" />
            </div>
            <img onClick={this.skip(10)} className="material-icons pforwardicon" src={forward} alt="fast_forward" id="fastForwardButton" />       
	    </div>

		<div id="resolutionContainer" className="presolutionContainer" style={{left: '0px'}}>
		   <div className="presolutioncontent">
		       <div id="totalresolution">Video Resolution : </div>
		       <div id="videoBitrate"> </div>
		       <div id="audioBitrate"> </div>
		       <div id="frameRate"> </div>
		   </div>
		</div>

		<div id="top_label" className="ptoplabel">
	      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
	       <div className="ptoplabelcontainer">

	       	<select className="select select_player pprofilesettings" id="select"  onFocus={this.profileFocus} onChange={this.changeProfile}>
	          <option value="-1">Auto</option>
	        </select>

	      	{ 
	          (this.state.showHideResolutionBtn)?( 
	          	<img id="hideResolution" onClick={this.hideResolution()} className="presoultionbtn" src={select} />
	          ) : ( <span></span> ) 
	        }
	        
	        <img id="showResolution" onClick={this.showResolution()} className="presoultionbtn" src={unselect} />
	        
	          <div id="fullscreenButton" onClick={this.toggleFullScreen()} className="material-icons pfullscreenbtn">
	            <img src={expand} alt="fullscreen" className="width_20" />
	          </div>
	        </div>
	    </div>

	    <div id="controls" className="toggle_pseekbarcontrols">
	        <div className="toggle_pseekbarcontainer">
	        	<div className="volume_control_container">
			        <img id="unmuteSpeaker" onClick={this.toggleVolume()} className="pvolumebtn" src={unMute} alt="Volume" />
			        <img id="muteSpeaker" onClick={this.toggleVolume()} className="pvolumebtn" src={mute} alt="Volume" />
			        <div className="vol-control">
		              <input id="vol-control" type="range" min="0" max="100" step="1" onChange={this.setVolume}></input>
		            </div>
		        </div>

	            <div className="volume_seek_container">
	            <input id="playerSeekbar" className="seekbar player_seekbar_input seekbar_input_controls" type="range" onChange={this.changeSeekbar}></input>
	            </div>
	        </div>

	        <div className="toggleLCEVC_container">
		    	<div className="toggleLCEVC_img"><img id="toggleLCEVC" src={lcevcToggle} alt="LCEVC" /></div>
		    	<span>&nbsp;&nbsp;</span>
		    	<div className="onoffswitch">
	              <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" defaultChecked={this.state.lcevcIsReady}
					  onChange={this.handleLcevcChange} />
	              <label className="onoffswitch-label" htmlFor="myonoffswitch">
	                  <span className="onoffswitch-inner"></span>
	                  <span className="onoffswitch-switch"></span>
	              </label>
	            </div>			    	
		    </div>

	    </div>

	    <div> 
	        { 
	          (this.state.showLoader)?( 
	          	<div id="lcevc_player_loading"></div>
	          ) : ( <span></span> ) 
	        }
	    </div>

	    <div> 
	        { 
	          (this.state.showSeekLoader)?( 
	          	<div id="loading_seek"></div>
	          ) : ( <span></span> ) 
	        }
	    </div>

	    </div>
	   
	</div>

      );
   }
}

export default TogglePlayer; 