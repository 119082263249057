import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import logout from './images/logout.png';
import logo from './images/logo_white.png';
import Modal from 'react-modal';
import * as constant from './Constant';

class Subscribe extends React.Component {

   constructor(props) {
    super(props);
    var self = this;
    this.state = {
      showUnsubscribe: 'false',
      showSubscribe: 'false',
      showLoader: 'false',
      showSubscribeBtn: 'true',
      showUnSubscribeBtn: 'true',
      amount: '',
      tenure: ''
 	  };
    this.onInit();
  }

  onInit() {
    var userName = localStorage.getItem('userName');
    var premiumContent = '0';
    if(userName && premiumContent == '1') {
      fetch('https://platform.v-nova.com/vnova_api/staging/entitlement.php', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "username":  userName,
          "master_id": constant.Master_id
        })
      }).then(response =>  response.json())
        .then(resData => {
          this.displaydata(resData)
        }).catch(error => console.log(error));
      } else {
          localStorage.clear();
          this.props.history.push('/')
      }
  }

  displaydata(resData) {
    if(resData.status_code == 100) {
        this.setState({
          showSubscribe: false,
        });
      } else {
        this.setState({
          showSubscribe: true,
        });
      }
    
  }

 
 render() {
  //console.log(this.state.showSubscribe);
    /**
    * Get local storage data's
    */
    const userName = localStorage.getItem('userName');
    const welcomeMsg = localStorage.getItem('welcomeMsg');
    const bgColor = localStorage.getItem('bgColor');
    const textColor = localStorage.getItem('textColor');

    /**
    * Internal styles
    */
    const colors = {
      backgroundColor: bgColor,
      color: textColor,
    }

    /**
    * Render Functions
    */
    this.payByMonth = () => (event) => {
      this.setState({
				amount: "2000",
        tenure: "month",
			});
    }
    this.payByYear = () => (event) => {
      this.setState({
				amount: "15000",
        tenure: "year",
			});
    }
    this.subscribeNow = () => (event) => {
      this.setState({showSubscribeBtn: 'false'});
      if(this.state.tenure) {
        window.location.href = "https://platform.v-nova.com/payments/index_staging.html?username="+ userName
         + "&amount=" + this.state.amount + "&platform=website&tenure=" + this.state.tenure + "";
      } else {
        this.setState({showSubscribeBtn: 'true'});
        alert("Choose a plan to subscribe");
      }
    }

    this.unSubscribeNow = () => (event) => {
      this.setState({showUnSubscribeBtn: 'false'});
      fetch('https://platform.v-nova.com/vnova_api/staging/unsubscribe.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "username":  userName,
        "master_id": constant.Master_id
      })
      }).then(response =>  response.json())
      .then(resData => {
        if(resData.status_code == 100) {
          alert(resData.success);
          this.setState({showUnSubscribeBtn: 'true'})
          window.location.reload();
          this.setState({
            showSubscribe: true,
          });
        }
      }).catch(error => this.setState({showUnSubscribeBtn: 'true'}));
    }

    this.logout = () => (event) => {
      event.preventDefault();
      localStorage.clear();
      this.props.history.push('/')
    }

   return (

    <div className="assetparent_height" style={colors}>

    	{/*logo section*/}
        <div className="col-md-12 col-sm-12 col-xs-12 text_align_right" style={colors}>
          <div className="col-xs-7 col-sm-7">
            <img src={logo} alt="LOGO" className="assetdetail_logo"/>
          </div>
          <div className="col-xs-5 col-sm-5 welcome_msg_container">
            <span className="welcome_msg">{welcomeMsg}</span>&emsp;<img src={logout} onClick={this.logout()} className="logout_icon"/>
          </div>
        </div>
      {/*End of logo section*/}

      {/*Subscribe Content*/}
        <div>

          { 
            (this.state.showSubscribe)?( 
              <div>
                <div className="col-xs-12 margin_top_25" style={colors}>
                  <div className="container">
                    <div className="row">
                      <div className="col-xs-12 text_align_center subscribe_info">
                        <h2>Subscribe to Perseus PLUS today to:</h2>
                        <p>- Access all premium movie and shows</p>
                        <p>- View without ads</p>
                        <p>- Enjoy videos in high quality</p>
                        <div className="pay_btn">
                          <button className="pay_btn1" id="myBtn" value="month" onClick={this.payByMonth()}>PAY MONTHLY <br/>&#8358;2000</button>
                          <button className="pay_btn1" id="myBtn" value="year" onClick={this.payByYear()}>PAY ANNUALLY <br/>&#8358;15000</button>
                        </div>

                        {
                          (this.state.showSubscribeBtn == 'true') ? (
                            <div id="subscribe_btn" className="subscribe_btn">
                              <button onClick={this.subscribeNow()}>SUBSCRIBE NOW</button>
                            </div>
                          ) : (
                            <div id="loader" className="col-md-12 login_btn_container padding_top_zero">
                              <div className="loader subscribeLoader"></div>
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : ( 
            <div>
              <div className="col-xs-12 margin_top_35">
                <div className="container">
                  <div className="row">
                    <div className="col-xs-12 text_align_center subscribe_info">
                      <h3>To unsubscribe from Perseus Plus, please click cancel subscription</h3>
                      {
                          (this.state.showUnSubscribeBtn == 'true') ? (
                            <div className="pay_btn">
                              <button onClick={this.unSubscribeNow()}>Cancel Subscription</button>
                            </div>
                          ) : (
                            <div id="loader" className="col-md-12 login_btn_container padding_top_zero">
                              <div className="loader subscribeLoader"></div>
                            </div>
                          )
                        }
                      
                    </div>
                  </div>
                </div>
              </div>

              

              </div>

            ) 
          }

        </div>
      {/*End of Subscribe Content*/}

    </div>

      );
   }
}

export default Subscribe;