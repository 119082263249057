import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import back from './images/goback.png';
import play from './images/1.png';
import pause from './images/2.png';
import rewind from './images/3.png';
import forward from './images/4.png';
import select from './images/select.png';
import unselect from './images/unselect.png';
import expand from './images/expand.png';

class videoPlayer extends React.Component {

   constructor(props) {
    super(props);
    var self = this;
    this.state = {
      	video: '',
      	canvas: '',
      	percentage:'',
      	trackList: [],
      	trackListLength : false,
      	showLoader:false,
      	showLiveBnt:false,
      	showHideResolutionBtn: false,
      	showPlayButton: false,
      	duration: ''
 	  };
    this.onInit();
  }

  	onInit() {
  		localStorage.setItem('routefromplayer', 'fromPlayer');
    	var manifestURL = localStorage.getItem('manifestURL');
    	var videoBaseURI = localStorage.getItem('videoBaseURI');
    	var isExternal = localStorage.getItem('external');
		var actualUrl = videoBaseURI + manifestURL;
		var category = localStorage.getItem('category');

		var interval = setTimeout(() => {

			this.video = document.getElementById('video');
      		this.canvas = document.getElementById('canvas');
			this.perseusSubs = document.getElementById("subtitles");

			this.player = window.videojs('video');

		    this.player.perseus(this.video,this.canvas,this.perseusSubs);
		    document.getElementById("canvas").style.width = "100%";
		    document.getElementById("canvas").style.height = "100%";

			
			if(category == 'live' && isExternal == '1') {
				this.sourceVid = document.getElementById('sourceVideo');
  				this.sourceVid.setAttribute('src', manifestURL);
			} else {
				this.sourceVid = document.getElementById('sourceVideo');
 				this.sourceVid.setAttribute('src', actualUrl);
			}
		  
	      	this.setState({
	          showHideResolutionBtn: true,
	          showPlayButton: true,
	        });

		    this.showResolutionBtn = document.getElementById("showResolution");
		    this.resolutionSection = document.getElementById("resolutionContainer");
		    this.hideResolutionBtn = document.getElementById("hideResolution");
		    this.showResolutionBtn.style.display = "block";
		    this.hideResolutionBtn.style.display = "none";
		    this.resolutionSection.style.display = "none";
	        var playBtn = document.getElementById("playButton");
	        var pauseBtn = document.getElementById("pauseButton");
	        playBtn.style.display = "none";
	        pauseBtn.style.display = "block";
	  	}, 1000);

	  	/*setTimeout(() => {
	  		this.selectProfile();
	    }, 3000);*/

	  	this.seekInterval = setInterval(() => {
	  		this.runSeek();this.recalcRates();
	  	}, 1000);

	  	this.timeout = setInterval(function() {
	       window.$('#top_label').slideUp(800);
	       window.$('#rewindButton').css('visibility','hidden');
	       window.$('#playButton').css('visibility','hidden');
	       window.$('#pauseButton').css('visibility','hidden');
	       window.$('#fastForwardButton').css('visibility','hidden');
	       window.$('#controls').slideUp(1000);
	    }, 8000);	
  	}

  	componentDidMount() {
  		window.scrollTo(0, 0);
  		this.video = document.getElementById('video');
	    this.video.addEventListener("seeking", this.onSeeking);
	    this.video.addEventListener("pause", this.onPause);
	    this.video.addEventListener("playing", this.isPlaying);
	    this.video.addEventListener("ended", this.isEnded);
  	}
  	componentWillUnmount() {
  		this.video = document.getElementById('video');
	    this.video.removeEventListener("seeking", this.onSeeking);
	    this.video.removeEventListener("pause", this.onPause);
	    this.video.removeEventListener("playing", this.isPlaying);
	    this.video.removeEventListener("ended", this.isEnded);
	    clearInterval(this.seekInterval);
	    clearInterval(this.timeout);
  	}
  	/*Onseeking function*/
	  onSeeking = (event) => {
	    this.setState({
          showLoader: true,
        });
	    window.$('#rewindButton').css('visibility','hidden');
	    window.$('#playButton').css('visibility','hidden');
	    window.$('#pauseButton').css('visibility','hidden');
	    window.$('#fastForwardButton').css('visibility','hidden');
	  }
	/*End of Onseeking function*/

	/*OnPause function*/
	  onPause = (event) => {
	    this.setState({
          showLoader: false,
        });
	    window.$('#rewindButton').css('visibility','visible');
	    window.$('#playButton').css('visibility','visible');
	    window.$('#pauseButton').css('visibility','visible');
	    window.$('#fastForwardButton').css('visibility','visible');
	  }
	/*End of OnPause function*/

	/*IsPlaying function*/
	  isPlaying = (event) => {
	    this.setState({
          showLoader: false,
        });
	    window.$('#rewindButton').css('visibility','visible');
	    window.$('#playButton').css('visibility','visible');
	    window.$('#pauseButton').css('visibility','visible');
	    window.$('#fastForwardButton').css('visibility','visible');
	  }
	/*End of IsPlaying function*/

	/*IsEnded function*/
	  isEnded = (event) => {
        if (document.fullscreenElement) {
	      document.exitFullscreen();
		}
		window.history.back();
	  }
	/*End of IsEnded function*/

  	

  	/*For seekbar*/
  	runSeek() {
	    this.video = document.getElementById("video");
	    var seekbarSpan = document.getElementById("seekbarSpan");

	    var dhours = Math.floor(this.video.duration / 3600);
	    var dminutes = Math.floor((this.video.duration % 3600) / 60);
	    var dseconds = Math.floor(this.video.duration - dminutes * 60);
	    
		var phours = Math.floor(this.video.currentTime / 3600);
	    var pminutes = Math.floor((this.video.currentTime % 3600) / 60);
	    var pseconds = Math.floor(this.video.currentTime - pminutes * 60);

	    if(dhours) {
	        var dtime = ('0' + dhours).slice(-2) +':'+('0' + dminutes).slice(-2) +':'+ ('0' + dseconds).slice(-2);
	    } else if(dminutes){
	        dtime = ('0' + dminutes).slice(-2) +':'+ ('0' + dseconds).slice(-2);
	    } else {
	        dtime = ('0' + dminutes).slice(-2) +':'+ ('0' + dseconds).slice(-2);
	    }
	    
	    if(phours) {
	        var ptime = ('0' + phours).slice(-2) +':'+('0' + pminutes).slice(-2) +':'+ ('0' + pseconds).slice(-2);
	    } else {
	        ptime = ('0' + pminutes).slice(-2) +':'+ ('0' + pseconds).slice(-2);
	    } 

	    this.setState({
	    	duration: dtime,
	    	playtime:  ptime,
	    })
	    if(this.video) {
	        this.percentage = ( this.video.currentTime / this.video.duration ) * 100;
	        var seekbarSpan = document.getElementById("seekbarSpan");
	        if(seekbarSpan) {
	        	seekbarSpan.style.width = this.percentage+"%";
	        }
      	}
  	}

  	/*For Video Resolution*/
	recalcRates() {
	  this.video = document.getElementById("video");
	  if (this.video) {
	  if (this.video.readyState <= HTMLMediaElement.HAVE_CURRENT_DATA || this.video.paused) {
	    this.canvas1 = document.getElementById('canvas');
	    this.d1 = document.getElementById("totalresolution");
	    this.d1.innerHTML = "Optimal Width: " + this.canvas1.width + "<br>" + "Optimal Height " + this.canvas1.height + "<br>";
	    return;
	  }

	  this.decodedPerSec = (this.video.webkitDecodedFrameCount - this.decodedFrames);
	  this.decodedFrames = this.video.webkitDecodedFrameCount;
	  this.audioBytesDecodedPerSec = this.video.webkitAudioDecodedByteCount - this.audioBytesDecoded;
	  this.audioBytesDecoded = this.video.webkitAudioDecodedByteCount;
	  this.videoBytesDecodedPerSec = this.video.webkitVideoDecodedByteCount - this.videoBytesDecoded;
	  this.videoBytesDecoded = this.video.webkitVideoDecodedByteCount;
	  this.droppedFramesPerSec = this.video.webkitDroppedFrameCount - this.droppedFrames;
	  this.droppedFrames = this.video.webkitDroppedFrameCount;
	  this.canvas1 = document.getElementById('canvas');
	  this.d1 = document.getElementById("totalresolution");

	  this.d1.innerHTML =
	  "Video Resolution : " + this.canvas1.width + " X " + this.canvas1.height + "<br>"+
	  "Audio bits decoded: " + this.video.webkitAudioDecodedByteCount*8 + "<br>" +"Audio bits decoded per sec: " + this.audioBytesDecodedPerSec + "<br>" +
	  "Video bits decoded: " + this.video.webkitVideoDecodedByteCount*8 + "<br>" +"Video bits decoded per sec: " + this.videoBytesDecodedPerSec + "<br>" +
	  "Decoded frames: " + this.video.webkitDecodedFrameCount +"<br>" + "Decoded frames per sec: " + this.decodedPerSec +"<br>" +
	  "Dropped frames: " + this.video.webkitDroppedFrameCount +"<br>"+"Dropped frames per sec: " + this.droppedFramesPerSec +"<br>";
	  }
	}

	onMouseEnterHandler() {
       // console.log('enter');
        window.$('#rewindButton').css('visibility','visible');
      	window.$('#playButton').css('visibility','visible');
      	window.$('#pauseButton').css('visibility','visible');
      	window.$('#fastForwardButton').css('visibility','visible');
      	window.$('#top_label').slideDown(1000);             
       	window.$('#controls').slideDown(1000);
    }

 render() {
 	
	const manifestURL = localStorage.getItem('manifestURL');
	const title = localStorage.getItem('title');
	const category = localStorage.getItem('category');
	const videoBaseURI = localStorage.getItem('videoBaseURI');
	const actualUrl = videoBaseURI + manifestURL;
	
	if(category == 'live'){
		this.setState({
            showLiveBnt: true,
        });
  	}

   	/**
	* Render Functions
	*/
    this.playControl = () => (event) => {
    	this.video = document.getElementById("video");
	    var playBtn = document.getElementById("playButton");
	    var pauseBtn = document.getElementById("pauseButton");
	    playBtn.style.display = "block";
	    pauseBtn.style.display = "none";
       if (this.video.paused) {
          this.video.play();
           playBtn.style.display = "none";
          pauseBtn.style.display = "block";
       } else {
          this.video.pause();
          playBtn.style.display = "block";
          pauseBtn.style.display = "none";
       }
    }

    this.goLive = () => (event) => {
    	this.video = document.getElementById('video');
    	var playBtn = document.getElementById("playButton");
	    var pauseBtn = document.getElementById("pauseButton");
      	var vidTime = this.video.duration ;
      	this.video.currentTime = vidTime;
      	this.video.play();
      	playBtn.style.display = "none";
	    pauseBtn.style.display = "block";
    }

    this.skip = (value) => (event) => {
    	this.video = document.getElementById("video");
	    var playBtn = document.getElementById("playButton");
	    var pauseBtn = document.getElementById("pauseButton");
	    this.video.currentTime += value;
	    this.video.play();
	    playBtn.style.display = "none";
	    pauseBtn.style.display = "block";
    }

    this.hideResolution = () => (event)  => {
	  this.showResolutionBtn.style.display = "block";
	  this.hideResolutionBtn.style.display = "none";
	  this.resolutionSection.style.display = "none";
	}
	this.showResolution = () => (event) => {
	  this.showResolutionBtn.style.display = "none";
	  this.hideResolutionBtn.style.display = "block";
	  this.resolutionSection.style.display = "block";
	}
	this.goback = () => (event) => {
		if (document.fullscreenElement) {
	      document.exitFullscreen();
		}
		window.history.back();
	}
	this.toggleFullScreen = () => (event) => {
		var elem = document.documentElement;
		 if (elem.requestFullscreen) {
	      elem.requestFullscreen();
	    } else if (elem.mozRequestFullScreen) {
	      /* Firefox */
	      elem.mozRequestFullScreen();
	    } else if (elem.webkitRequestFullscreen) {
	      /* Chrome, Safari and Opera */
	      elem.webkitRequestFullscreen();
	    } else if (elem.msRequestFullscreen) {
	      /* IE/Edge */
	      elem.msRequestFullscreen();
	    }
	    if (document.fullscreenElement) {
	      document.exitFullscreen();
		}   
	}

	this.customSeekbar = () => (event) => {
		this.video = document.getElementById("video");
	    var playBtn = document.getElementById("playButton");
	    var pauseBtn = document.getElementById("pauseButton");
	    var offset = window.$('#custom-seekbar').offset();
	    var left = (event.pageX - offset.left);
	    var totalWidth = window.$('#custom-seekbar').width();
	    var percentage = ( left / totalWidth );
	    this.vidTime = this.video.duration * percentage;
	    this.video.currentTime = this.vidTime;
	    this.video.play();
	    playBtn.style.display = "none";
	    pauseBtn.style.display = "block";
	}

	this.changeProfile = (event) => {
		//console.log(this.state.hplayer);
	    var newLevel = event.target.value; 
	    //console.log(newLevel);
	    if(newLevel != "-1"){
	        this.hplayer.currentLevel = newLevel;
	    } else {
	        this.hplayer.currentLevel = -1;
	     }
	};


   return (

	<div className="pplayercontainer" onMouseMove={this.onMouseEnterHandler}>

		<div className="clickable pclickableContainer">
			<video id="video" className="pvideotag vjs-default-skin" preload="auto" autoPlay><source id="sourceVideo" ></source></video>
			<canvas id="canvas" className="pcanvastag"></canvas>
			<div id="subtitles" className="bottom-centered" ></div>
		</div>
		<div>
            <img onClick={this.skip(-10)} id="rewindButton" className="material-icons prewindicon" src={rewind} alt="fast_rewind" />
            <div className="playVideo" onClick={this.playControl()}>
            	{ 
		          (this.state.showPlayButton)?( 
		          	<img className="pplaypauseicon" id="playButton" src={play} alt="play" /> 
		          ) : ( <span></span> ) 
		        }
	           
	           <img className="pplaypauseicon" id="pauseButton" src={pause} alt="pause" />
            </div>
            <img onClick={this.skip(10)} className="material-icons pforwardicon" src={forward} alt="fast_forward" id="fastForwardButton" />       
	    </div>

		<div id="resolutionContainer" className="presolutionContainer">
		   <div className="presolutioncontent">
		       <div id="totalresolution">Video Resolution : </div>
		   </div>
		</div>

		<div id="top_label" className="ptoplabel">
			<span><img id="goback" onClick={this.goback()} className="pplayergoback" src={back} />&nbsp;&nbsp;</span>
	       <label className="ptoplabeltitle">powered by V-NOVA</label>
	       <div className="ptoplabelcontainer">
	
	      	{
	          (this.state.showHideResolutionBtn)?( 
	          	<img id="hideResolution" onClick={this.hideResolution()} className="presoultionbtn" src={select} />
	          ) : ( <span></span> ) 
	        }
	        
	        <img id="showResolution" onClick={this.showResolution()} className="presoultionbtn" src={unselect} />
	          <div id="fullscreenButton" onClick={this.toggleFullScreen()} className="material-icons pfullscreenbtn">
	            <img src={expand} alt="fullscreen" className="width_20" />
	          </div>
	        </div>
	    </div>

	    <div id="controls" className="pseekbarcontrols">
	        <div className="pseekbarcontainer">
	        	{ 
			        (this.state.showLiveBnt)?(
			            <span></span>
			        ) : ( <span>{this.state.playtime}</span> ) 
		        }
	            <div className="pseekbar" id="custom-seekbar" onClick={this.customSeekbar()}>
	               <span id="seekbarSpan" className="pseekbarspan"></span>
	            </div>
	            { 
			        (this.state.showLiveBnt)?(
			            <span></span>
			        ) : ( <span>
			        	{
			        		(this.state.duration != 'aN:aN')?(<span>{this.state.duration}</span>):(<span></span>)
			        	}
			        	

			        </span> ) 
		        }

	            { 
		          (this.state.showLiveBnt)?( 
		          	<span><button id="livebtn"onClick={this.goLive()} className="livebtn">Live</button></span>
		          ) : ( <span></span> ) 
		        }
	            
	        </div>

	    </div>

	    <div> 
	        { 
	          (this.state.showLoader)?( 
	          	<div id="loading"></div>
	          ) : ( <span></span> ) 
	        }
	    </div>
	   
	</div>

      );
   }
}

export default videoPlayer;