// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_RJXJBiudH",
    "aws_user_pools_web_client_id": "7j4f3b9p006jtlelbifqrh8f8n",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://d5yilngzf5binpc4jt5pyyjh44.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

export default awsmobile;