import React from 'react';
import './App.css';
import logo from './images/logo.png';
import MpegLcevcLogo from './images/mpeg5_lcevc_logo.png';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

class ChangePassword extends React.Component {
 

   constructor(props) {
    super(props);
    this.state = {
      code: '',
      cpass:'',
      confirmpassword: '',
      showLoginBtn: true,
      showPassword: false,
      showConfirmPassword: false,
      isStaging: false,
 };
    this.onInit();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onInit() {
    var userReuestChangePwd = localStorage.getItem('userReuestChangePwd');
    if(!userReuestChangePwd) {
      alert('Only valid user can reset the password.')
      this.props.history.push('/forgotPassword')
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit(event) {
    var userReuestChangePwd = localStorage.getItem('userReuestChangePwd');
    
    event.preventDefault();
    
    if(this.state.code.match(/\s/g)) {
      alert('Spaces are not allowed in the verification code field');
      window.$("#code").focus();
    } else if(this.state.cpass.match(/\s/g)) {
      alert('Spaces are not allowed in the password field');
      window.$("#cpass").focus();
    } else if(/[^A-Za-z\d]/.test(this.state.cpass)) {
      alert('Special characters are not allowed in the password field');
      window.$("#cpass").focus();
    } 

    else if(this.state.confirmpassword.match(/\s/g)) {
      alert('Spaces are not allowed in confirm password field');
      window.$("#confirmpassword").focus();
    } else if(/[^A-Za-z\d]/.test(this.state.confirmpassword)) {
      alert('Special characters are not allowed in the confirm password field');
      window.$("#confirmpassword").focus();
    }

    else if (this.state.cpass != this.state.confirmpassword) {
      alert("Password didn't match");
    } else {
      this.setState({
        showLoginBtn : false,
      });
      Auth.forgotPasswordSubmit(userReuestChangePwd, this.state.code, this.state.cpass)
      .then(data => this.displaydata(data))
      .catch(err => this.displayerror(err)); 
    }
    
  }

   displaydata(data) {
    alert("Password successfully changed");
    localStorage.clear();
    this.props.history.push('/')
  }

  displayerror(err) {
    //console.log(err);
    this.setState({
          showLoginBtn : true,
        });
     alert(err.message)
  }


 render() {
  this.toggleShowPassword = () => (event) => {
    this.setState({ showPassword: !this.state.showPassword });
  }
  this.toggleConfirmShowPassword = () => (event) => {
      this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }

   return (
      <div className="container-fluid prelogin_bg">
      <div className="row">

      <div className="col-md-offset-3 col-md-8 col-xs-12 prelogin_container">
      <div className="col-md-2 col-sm-3 col-xs-1"></div>
      <div className="col-md-5 col-sm-6 col-xs-10 change_pwd_container">
      <form id="changepwd-form" name="form"  onSubmit={this.handleSubmit}  role="form">
      
      <div className="logo_container">
        {
          (this.state.isStaging) ? (
            <span>
              <img src={logo} alt="LOGO"/>
              <div><img src={MpegLcevcLogo} alt="MPEG-5 LCEVC"/></div>
            </span>
          ) : (
            <span>
              <img src={logo} alt="LOGO"/>
              <div><img src={MpegLcevcLogo} alt="MPEG-5 LCEVC"/></div>
            </span>
          )
        }
      </div>

      <div className="col-md-12">
      <div className="col-md-2"></div>
      <div className="form-group col-md-8 access_login_form_group">                
      <div className="login__row display_flex">
      <input className="login__input" type="text" id="code" value={this.state.code} onChange={this.handleChange} autoFocus required/>
      <span className="access-floating-label">Verification Code</span>
      </div>
      </div>
      <div className="col-md-2"></div>
      </div>

      <div className="col-md-12">
      <div className="col-md-2"></div>
      <div className="form-group col-md-8 access_login_form_group ">                
      <div className="login__row display_flex">
      {
        (this.state.showPassword) ? (
          <span><input className="login__input" type="text" autoComplete="new-password" id="cpass" value={this.state.cpass} onChange={this.handleChange} minLength="8" required />
          <span className="access-floating-label">New Password</span></span>
        ) : (
          <span><input className="login__input" type="password" autoComplete="new-password" id="cpass" value={this.state.cpass} onChange={this.handleChange} minLength="8" required />
          <span className="access-floating-label">New Password</span></span>
        )
      }
      {
        (this.state.showPassword) ? (
          <span className="glyphicon glyphicon-eye-open form-control-feedback toggle_password top_27" onClick={this.toggleShowPassword()}></span>
        ) : (
          <span className="glyphicon glyphicon-eye-close form-control-feedback toggle_password top_27" onClick={this.toggleShowPassword()}></span>
        )
      }
      </div>
      </div>
      <div className="col-md-2"></div>
      </div> 

      <div className="col-md-12">
      <div className="col-md-2"></div>
      <div className="form-group col-md-8 access_login_form_group ">                
      <div className="login__row display_flex">
      {
        (this.state.showConfirmPassword) ? (
          <span><input className="login__input" type="text" id="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} minLength="8" required />
          <span className="access-floating-label">Confirm Password</span></span>
        ) : (
          <span><input className="login__input" type="password" id="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} minLength="8" required />
          <span className="access-floating-label">Confirm Password</span></span>
        )
      }
      {
        (this.state.showConfirmPassword) ? (
          <span className="glyphicon glyphicon-eye-open form-control-feedback toggle_password top_27" onClick={this.toggleConfirmShowPassword()}></span>
        ) : (
          <span className="glyphicon glyphicon-eye-close form-control-feedback toggle_password top_27" onClick={this.toggleConfirmShowPassword()}></span>
        )
      }
      </div>
      </div>
      <div className="col-md-2"></div>
      </div> 

     

      {
        (this.state.showLoginBtn) ? (
          <div className="col-md-12 login_btn_container padding_top_20">
             <button className="btn login_btn login_btn_pwd" type="submit" value="Submit"><span>Reset Password</span></button>  
          </div>
        ) : (
          <div id="loader" className="col-md-12 login_btn_container loginLoaderContainer">
            <div className="loader loginLoader"></div>
          </div>
        )
      }
      
      </form>
     
      </div>
      <div className="col-md-5 col-sm-3 col-xs-1"></div>
      </div>


      </div>
      </div>   
      );
   }

}

export default ChangePassword;